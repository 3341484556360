import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Event } from "../types/Event";
import { Corporate } from "../types/Corporate";
import { useAuth } from "../providers/useAuth";
import { firestoreDb } from "../config/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const corporationsColRef = collection(firestoreDb, "corporations");
const eventsColRef = collection(firestoreDb, "events");

const useDestination = () => {
  const { userData } = useAuth();
  const params = useParams();

  return {
    useDestinationsInFocus: () =>
      useQuery({
        queryKey: ["focused-destination", userData?.selected_plan_type],
        queryFn: async () => {
          if (params.slug) {
            console.log("slug", params.slug);
            //check events
            const eventDoc = (
              await getDocs(
                query(eventsColRef, where("slug", "==", params.slug))
              )
            ).docs[0];
            if (eventDoc)
              return [new Event({ ...eventDoc.data(), id: eventDoc.id })];
            //check corporations
            const corporateDoc = (
              await getDocs(
                query(corporationsColRef, where("slug", "==", params.slug))
              )
            ).docs[0];
            if (corporateDoc)
              return [
                new Corporate({ ...corporateDoc.data(), id: corporateDoc.id }),
              ];

            return [];
          } else if (userData?.selected_plan_type === "corporate_manager") {
            const corporateDoc = await getDoc(
              doc(firestoreDb, `corporations/${userData.corporate}`)
            );
            if (corporateDoc.exists())
              return [
                new Corporate({ ...corporateDoc.data(), id: corporateDoc.id }),
              ];
            return [];
          } else if (userData?.selected_plan_type === "event_manager") {
            const eventDocs = await getDocs(
              query(eventsColRef, where("createdBy", "==", userData.id))
            );
            return eventDocs.docs.map(
              (doc) => new Event({ ...doc.data(), id: doc.id })
            );
          } else {
            return [];
          }
        },
      }),
  };
};

export default useDestination;
