import moment from "moment";
import { useEffect, useMemo } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { MdOutlineCancel } from "react-icons/md";
import colors from "../../assets/colors";
import useCorporate from "../../hooks/useCorporate";
import useStripe from "../../hooks/useStripe";
import { Corporate } from "../../types/Corporate";
import ControlledGoogleAutoComplete from "../common/ControlledGoogleAutoComplete";
import ControlledInput from "../common/ControlledInput";
import CustomButton from "../common/CustomButton";
import CustomCheckbox from "../common/CustomCheckbox";
import ModalHeader from "../common/ModalHeader";
import { useMap } from "../common/MapComponent";

export type EditCorporateModalProps = {
  corporate?: Corporate;
  hideHeader?: boolean;
};

const EditCorporateDetails = ({
  hide,
  props,
}: {
  hide: () => void;
  props: EditCorporateModalProps;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm<Corporate>({ defaultValues: props.corporate });
  const { fields, append, remove } = useFieldArray({
    name: "branches",
    control,
  });
  const { updateCorporate, onboardCorporate } = useCorporate();
  const { createCheckoutSession, redirectToStripeCustomerPortal } = useStripe();
  const [map] = useMap();

  const onSubmit: SubmitHandler<Corporate> = (data) => {
    console.log(!!props.corporate);
    if (!data.branches.length)
      return alert("You must add at least one location.");
    if (!!props.corporate) {
      updateCorporate.mutate(data, {
        onSuccess: () => {
          if (data.branches[0])
            map?.moveCamera({
              center: {
                lat: data.branches[0].location.coordinates.latitude,
                lng: data.branches[0].location.coordinates.longitude,
              },
              zoom: 16,
            });
          hide();
        },
      });
    } else {
      console.log("onboarding...");
      onboardCorporate.mutate(data, {
        onSuccess: () => {
          if (data.branches[0])
            map?.moveCamera({
              center: {
                lat: data.branches[0].location.coordinates.latitude,
                lng: data.branches[0].location.coordinates.longitude,
              },
              zoom: 16,
            });
        },
      });
    }
  };

  console.log(watch());

  const onStatusButtonClick = async () => {
    if (statusButtonLabel === "Activate")
      createCheckoutSession.mutate({ corporate_id: props.corporate!.id });
    else if (
      statusButtonLabel === "Complete Activation" ||
      statusButtonLabel === "Cancel Plan" ||
      statusButtonLabel === "Renew Plan"
    ) {
      redirectToStripeCustomerPortal.mutate();
    }
  };

  const statusColor = useMemo(() => {
    if (!props.corporate) return colors.PRIMARY;

    if (
      !props.corporate.subscription ||
      props.corporate.subscription.status !== "active"
    )
      return colors.RED;

    return colors.PRIMARY;
  }, [props]);

  const statusButtonLabel = useMemo(() => {
    if (!props.corporate) return "";

    if (!props.corporate?.subscription?.status) return "Activate";

    if (props.corporate.subscription.status === "inactive") return "Activate";

    if (props.corporate.subscription.status === "incomplete")
      return "Complete Activation";

    if (props.corporate.subscription.cancel_at) return "Renew Plan";

    if (props.corporate.subscription.status === "active") return "Cancel Plan";

    return "Activate";
  }, [props]);

  return (
    <div style={{ margin: -30, minWidth: 500 }}>
      {!props.hideHeader && (
        <ModalHeader
          style={{ padding: 30, paddingBottom: 10 }}
          hide={hide}
          title="Corporate Details"
          subtitle="Update corporate details"
        />
      )}

      {!props.hideHeader && (
        <div style={{ height: 1, background: colors.BLACK_20 }} />
      )}

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          maxHeight: "75vh",
          padding: 30,
        }}
      >
        <ControlledInput
          label="Name"
          inputProps={{ placeholder: "Add corporate name here..." }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "name",
            options: { required: true },
          }}
        />

        <p style={{ fontSize: 14, marginBottom: 6, textAlign: "start" }}>
          Locations
        </p>

        <div className="flex flex-col">
          {fields.map((field, index) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <div className="w-[1px] h-4 bg-gray-200 ml-4" />
                )}
                <div
                  className="border border-dashed rounded p-4 pt-3 relative"
                  key={index}
                >
                  {index !== 0 && (
                    <MdOutlineCancel
                      onClick={() => remove(index)}
                      className="hover-tint hover-border bg-gray-100"
                      style={{
                        padding: 2,
                        color: colors.RED,
                        width: 25,
                        borderRadius: 5,
                        height: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 5,
                        right: 5,
                      }}
                    />
                  )}

                  <ControlledInput
                    key={field.id}
                    label="Name"
                    inputProps={{ placeholder: "Add office name here..." }}
                    containerStyle={{ flex: 1 }}
                    formProps={{
                      register,
                      errors,
                      name: `branches.${index}.name`,
                      options: { required: true },
                    }}
                  />

                  <ControlledGoogleAutoComplete
                    label="Location"
                    containerStyle={{ flex: 1, marginTop: 10 }}
                    formProps={{
                      errors,
                      name: `branches.${index}.location`,
                      options: { required: true },
                      control,
                    }}
                  />

                  <div className="flex items-center">
                    <Controller
                      control={control}
                      name={`branches.${index}.nft_enabled`}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomCheckbox
                            containerStyle={{ paddingTop: 10, flex: 1 }}
                            label="Enable nft minting"
                            value={value}
                            onClick={() => onChange(!value)}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name={`branches.${index}.public`}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <CustomCheckbox
                            containerStyle={{ paddingTop: 10, flex: 1 }}
                            label="Available for public view"
                            value={value}
                            onClick={() => onChange(!value)}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <CustomButton
            secondary
            style={{ marginTop: 20 }}
            onClick={() => append({ name: "" } as any)}
            label="Add Location"
          />
        </div>

        {!!props.corporate && (
          <div
            style={{ borderColor: statusColor }}
            className="mt-5 border border-dashed rounded relative p-4"
          >
            <p
              style={{ color: statusColor }}
              className="font-semibold text-sm bg-white text-start absolute top-[-10px] left-4"
            >
              CORPORATE PLAN -{" "}
              {props?.corporate?.subscription?.status?.toUpperCase() ||
                "INACTIVE"}
              {props.corporate.subscription?.cancel_at
                ? " - ENDS ON " +
                  moment(props.corporate.subscription.cancel_at * 1000).format(
                    "MMM Do YYYY"
                  )
                : ""}
            </p>
            <CustomButton
              secondary
              loading={
                redirectToStripeCustomerPortal.isLoading ||
                createCheckoutSession.isLoading
              }
              style={{
                borderRadius: 4,
                background:
                  statusButtonLabel === "Cancel Plan"
                    ? colors.RED + "20"
                    : colors.PRIMARY + "20",
              }}
              onClick={onStatusButtonClick}
              label={statusButtonLabel}
            />
          </div>
        )}
      </div>

      <div className="bg-gray-100" style={{ height: 1 }} />

      <CustomButton
        // disabled={!isDirty}
        onClick={handleSubmit(onSubmit)}
        loading={updateCorporate.isLoading}
        style={{ margin: 20, marginTop: 20 }}
        label={props.corporate ? "SAVE CHANGES" : "SUBMIT"}
      />
    </div>
  );
};

export default EditCorporateDetails;
