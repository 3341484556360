import moment from "moment";
import { useQuery } from "react-query";
import { createGlobalState } from "react-use";

import useEvent from "./useEvent";
import { useAuth } from "../providers/useAuth";
import { db, functions } from "../config/firebase";
import { Carpool, CarpoolStatus } from "../types/Carpool";
import { TripDirection } from "../assets/data/CarpoolsData";
import { useFirestoreRealtimeDoc, useFirestoreRealtimeData } from "./firebase";

const carpoolsColRef = db.collection("carpools");

export enum TimeFilter {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  All = "all",
}

export const useSelectedCarpool = createGlobalState<Carpool | undefined>();

const useCarpool = () => {
  const { slugEvent } = useEvent();
  const { userData } = useAuth();

  return {
    useAllActiveCarpools: <TData = Carpool[]>(
      transform: (data: Carpool[]) => TData = (d) => d as unknown as TData
    ) =>
      useFirestoreRealtimeData({
        query: carpoolsColRef
          .where("status", "in", ["open", "journey", "pickups", "pickup"])
          .limit(20),
        select: (snapshot) => {
          const carpools =
            snapshot?.docs?.map(
              (doc) => new Carpool({ id: doc.id, ...doc.data() }, "user_id")
            ) || [];
          return transform(carpools);
        },
      }),

    useCompletedCarpools: <TData = Carpool[]>(
      transform: (data: Carpool[]) => TData = (d) => d as unknown as TData,
      limit?: number
    ) => {
      let query = carpoolsColRef
        .where("status", "==", "completed")
        .orderBy("createdAt", "desc")
        .limit(limit || 100);

      if (slugEvent) query = query.where("destination.id", "==", slugEvent.id);

      if (userData?.corporate)
        query = query.where("corporate", "==", userData.corporate);

      return useFirestoreRealtimeData({
        query,
        select: (snapshot) => {
          const carpools =
            snapshot?.docs?.map(
              (doc) => new Carpool({ id: doc.id, ...doc.data() }, "user_id")
            ) || [];
          return transform(carpools);
        },
        resetOn: [slugEvent?.id, userData?.corporate],
      });
    },
    useCarpoolsByTripDirection: <TData = Carpool[]>(
      tripDirection: TripDirection,
      transform: (data: Carpool[]) => TData = (d) => d as unknown as TData
    ) =>
      useFirestoreRealtimeData({
        query: carpoolsColRef
          .where("tripDirection", "==", tripDirection)
          .limit(20),
        select: (snapshot) => {
          const carpools =
            snapshot?.docs?.map(
              (doc) => new Carpool({ id: doc.id, ...doc.data() }, "user_id")
            ) || [];
          return transform(carpools);
        },
      }),

    useFilterCarpoolsByTimePeriod: <TData = Carpool[]>(
      period: TimeFilter = TimeFilter.All,
      carpoolStatus: CarpoolStatus,
      transform: (data: Carpool[]) => TData = (d) => d as unknown as TData
    ) => {
      const tomorrow = moment().valueOf() + 1000 * 60 * 60 * 24; // Current timestamp in milliseconds
      let startDate: number | null = null;

      switch (period) {
        case TimeFilter.Day:
          startDate = moment().startOf("day").valueOf();
          break;
        case TimeFilter.Week:
          startDate = moment().startOf("week").valueOf();
          break;
        case TimeFilter.Month:
          startDate = moment().startOf("month").valueOf();
          break;
        case TimeFilter.Year:
          startDate = moment().startOf("year").valueOf();
          break;
        case TimeFilter.All:
          startDate = null;
          break;
        default:
          throw new Error("Invalid period specified");
      }

      let query = carpoolsColRef.where("status", "==", carpoolStatus);

      if (startDate !== null) {
        query = query
          .where("completedAt", ">=", startDate)
          .where("completedAt", "<=", tomorrow)
          .orderBy("completedAt", "desc")
          .orderBy("createdAt", "desc")
          .limit(20);
      } else {
        query = query.orderBy("createdAt", "desc").limit(20);
      }

      if (userData?.corporate) {
        query = query.where("corporate", "==", userData.corporate);
      }

      return useFirestoreRealtimeData({
        query,
        select: (snapshot) => {
          const carpools =
            snapshot?.docs?.map(
              (doc) => new Carpool({ id: doc.id, ...doc.data() }, userData?.id)
            ) || [];
          return transform(carpools);
        },
        resetOn: [period],
      });
    },

    useVenueLeaderBoard: <TData = Carpool[]>(
      transform: (data: Carpool[]) => TData = (d) => d as unknown as TData
    ) =>
      useFirestoreRealtimeData({
        query: carpoolsColRef
          .where("status", "==", "cancelled")
          .orderBy("createdAt", "desc")
          .limit(20),
        select: (snapshot) => {
          const carpools =
            snapshot?.docs?.map(
              (doc) => new Carpool({ id: doc.id, ...doc.data() }, "user_id")
            ) || [];
          return transform(carpools);
        },
      }),

    useCarpoolStats: () => {
      let query = carpoolsColRef.where(
        "createdAt",
        ">",
        Date.now() - 1000 * 60 * 60 * 48
      );

      if (userData?.corporate)
        query = query.where("corporate", "==", userData.corporate);

      return useFirestoreRealtimeData({
        query: query,
        select: (snapshot) => {
          const carpools = snapshot?.docs?.map(
            (doc) =>
              new Carpool({ id: doc.id, ...doc.data() }, userData?.corporate)
          );

          const todayCarpools = carpools?.filter(
            (carpool) =>
              carpool.createdAt > moment().startOf("day").toDate().getTime()
          );

          return {
            onlineCarpools: todayCarpools?.filter(
              (carpool) =>
                carpool.status === "open" ||
                carpool.status === "journey" ||
                carpool.status === "pickup"
            ).length,
            offlineCarpools: todayCarpools?.filter(
              (carpool) => carpool.status === "completed"
            ).length,
            activeToday: todayCarpools?.length || 0,
            joinedToday: 0,
          };
        },
        resetOn: [userData?.corporate],
      });
    },
    useRealtimeCarpool: (carpoolId?: string) =>
      useFirestoreRealtimeDoc({
        query: carpoolsColRef.doc(carpoolId || ""),
        select: (doc) => new Carpool(doc?.data(), userData?.id),
        deps: [carpoolId],
      }),

    useCarpoolPreview: (carpoolId?: string, carpool?: Carpool) =>
      useQuery({
        queryKey: [
          "carpool-preview",
          carpoolId,
          carpool?.passengersArray?.map((p) => p.status),
        ],
        queryFn: async () =>
          await functions.httpsCallable("getCarpoolPreview")({ carpoolId }),
        enabled: !!carpoolId,
        select: (data) => new Carpool(data.data, userData?.id),
        keepPreviousData: true,
      }),
  };
};

export default useCarpool;
