import moment from "moment";

export class Event {
  constructor(data?: any) {
    Object.assign(this, data);
  }

  location!: Location;
  venue!: string;
  date!: number;
  endDate!: number;
  status!: "active" | "inactive" | "archived";
  name!: string;
  id!: string;
  subscribers!: string[];
  access!: "locked" | "open";
  startTime!: string;
  endTime!: string;
  description!: string;
  code?: string;
  type: "event" = "event";
  review_status?: EventReviewStatus;
  expectedTurnout?: { min: number; max: number };
  bonuses: string[] = [];
  bounty: number = 0;
  createdBy!: string;
  slug?: string;
  goals?: {
    co2: number;
  };
  eventLogo?: string;
  images?: {
    image1: string | null;
    image2: string | null;
    image3: string | null;
    image4: string | null;
    image5: string | null;
  } = {
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  };
  radiuses?: {
    origin: number;
    destination: number;
  };
  aggregate?: {
    credits?: number;
    co2Amount?: number;
  } = { credits: 0, co2Amount: 0 };
  skin?: Skin;

  payment_status?: "active";

  get eventFormattedDate() {
    const startMonth = moment(this.date).format("MMMM");
    const endMonth = moment(this.endDate || this.date).format("MMMM");
    const startDate = moment(this.date).format("Do");
    const endDate = moment(this.endDate || this.date).format("Do");

    const time =
      this.startTime === this.endTime
        ? this.startTime
        : `${this.startTime} - ${this.endTime}`;

    return `${startDate} ${startMonth === endMonth ? "" : startMonth}${
      endDate === startDate ? "" : " - "
    }${endDate === startDate ? "" : endDate + " "}${endMonth}, ${time}`;
  }

  get marker() {
    return { location: this.location, type: "event" as const, data: this };
  }
}

export type Skin = {
  backgroundColors: string[];
  light: boolean;
  type: "skin";
};

export type Location = {
  coordinates: Coordinate;
  geohash: string;
  type?: "event";
  id?: string;
  address?: string;
};

export type Coordinate = { latitude: number; longitude: number };

export type EventReviewStatus = "approved" | "in_review" | "declined";
