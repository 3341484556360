import React, { useMemo } from "react";
import useFirestoreRealtimeDoc from "../hooks/firebase/useFirestoreRealtimeDoc";
import { db } from "../config/firebase";
import { useAuth } from "./useAuth";
import { GamificationTemplate } from "../types/Gamification";

interface GamificationContextInterface {
  template?: GamificationTemplate;
  userGamificationData?: {
    cards: {
      exp_at: number;
      id: string;
      progress: number;
      status: "active" | "inactive";
    }[];
  };
}

export const GamificationContext = React.createContext(
  {} as GamificationContextInterface
);

const GamificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, userData } = useAuth();

  const { data: baseTemplate } = useFirestoreRealtimeDoc({
    deps: [],
    query: db.collection("gamification_templates").doc("base"),
    select: (doc) => ({ ...doc?.data(), id: doc?.id } as any),
  });

  const { data: corporateTemplate } = useFirestoreRealtimeDoc({
    deps: [userData?.corporate],
    query: db
      .collection("gamification_templates")
      .doc(userData?.corporate || "-1"),
    select: (doc) => ({ ...doc?.data(), id: doc?.id } as any),
  });

  const template = useMemo(
    () => (corporateTemplate?.cards ? corporateTemplate : baseTemplate),
    [corporateTemplate, baseTemplate]
  );

  const { data: userGamificationData } = useFirestoreRealtimeDoc({
    deps: [template, user?.uid],
    query: db.collection("gamification_data").doc(user?.uid || "-"),
    select: (doc) => doc?.data() as any,
  });

  return (
    <GamificationContext.Provider value={{ userGamificationData, template }}>
      {children}
    </GamificationContext.Provider>
  );
};

export default GamificationProvider;
