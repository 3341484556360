import { FC, useState } from "react";

import { Roles } from "../types/Role";
import useUser from "../hooks/useUser";
import { useAuth } from "../providers/useAuth";

const Users: FC = (): JSX.Element => {
  const { useUsers, changeUserRole } = useUser();
  const [emailFilter, setEmailFilter] = useState("");

  const { data: users = [] } = useUsers(emailFilter);
  const { userData } = useAuth();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(e.target.value);
  };

  const handleRoleChange = (userId: string | undefined, newRole: string) => {
    if (userId) {
      changeUserRole(userId, newRole);
    }
  };

  if (userData?.role !== Roles.Admin) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-md rounded-md">
          <h1 className="text-2xl font-bold text-red-600 mb-4">
            Not authorized
          </h1>
          <p className="text-gray-700">
            You do not have permission to access this page.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <input
        type="text"
        value={emailFilter}
        onChange={handleEmailChange}
        placeholder="Filter by email"
        className="mb-4 p-2 border border-gray-300 rounded-md"
      />
      {users.length === 0 ? (
        <p className="text-3xl text-gray-400 italic">Users...</p>
      ) : (
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-b border-gray-200">ID</th>
              <th className="px-4 py-2 border-b border-gray-200">Name</th>
              <th className="px-4 py-2 border-b border-gray-200">Email</th>
              <th className="px-4 py-2 border-b border-gray-200">Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className="odd:bg-white even:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-200">
                  {user.id}
                </td>
                <td className="px-4 py-2 border-b border-gray-200">
                  {user.username || user.full_name}
                </td>
                <td className="px-4 py-2 border-b border-gray-200">
                  {user.email}
                </td>
                <td className="px-4 py-2 border-b border-gray-200">
                  <select
                    className="block w-full bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <option value={Roles.Admin}>Admin</option>
                    <option value={Roles.AccountManager}>
                      Account Manager
                    </option>
                    <option value={Roles.ClientAdmin}>Client Admin</option>
                    <option value={Roles.ClientAccountManager}>
                      Client Account Manager
                    </option>
                    <option value={Roles.ClientCarpoolingUser}>
                      Client Carpooling User
                    </option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Users;
