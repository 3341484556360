import { useMemo, useRef, useState } from "react";
import { MdCopyAll } from "react-icons/md";

import { colors } from "../../assets";
import useBounty from "../../hooks/useBounty";
import useCorporate from "../../hooks/useCorporate";
import useEvent, { useSelectedEvent } from "../../hooks/useEvent";
import useModal from "../../hooks/useModal";
import useUser, { useSelectedUser } from "../../hooks/useUser";
// import { useAuth } from "../../providers/AuthProvider";
import {} from "../../pages/Dashboard/Home";
import { CorporateBranch } from "../../types/Corporate";
import { Event } from "../../types/Event";
import { User } from "../../types/User";
import Avatar from "../common/Avatar";
import Card from "../common/Card";
import ImageIcon from "../common/ImageIcon";
import { useMapCenter } from "../common/MapComponent";
import EventSummary, { EventSummaryModalProps } from "../modals/EventSummary";
import SponsorEvent, { SponsorEventModalProps } from "../modals/SponsorEvent";
import useStats from "../../hooks/useStats";
import { useAuth } from "../../providers/useAuth";
import { leaderboardTypes } from "../../common/Constants";

const Leaderboard = ({
  leaderboardType: selectedLeaderboardValue,
}: {
  leaderboardType: string;
}) => {
  const { userData } = useAuth();
  const { useMyBounties } = useBounty();
  const { data: bounties = [] } = useMyBounties();
  const { useIdsToUsers } = useUser();
  const { useVenueLeaderboard } = useEvent();
  const [sortBy, setSortBy] = useState<"total_co2" | "credits">("total_co2");
  const { data: users = [] } = useIdsToUsers(
    bounties.reduce<string[]>((t, c) => [...t, ...(c.claimants || [])], [])
  );
  const { data: events = [] } = useVenueLeaderboard();

  const listHeaderRef = useRef<HTMLDivElement>(null);

  const selectedLeaderboardType = leaderboardTypes.find(
    (l) => l.value === selectedLeaderboardValue
  );

  //for now just return the corporate users since we are only supporting that right now.
  //   const List = useMemo(() => {
  //     if (selectedLeaderboardValue === 0) {
  //       if (userData?.selected_plan_type === "event_manager")
  //         return <BountyParticipantList listHeaderRef={listHeaderRef} />;
  //       if (userData?.selected_plan_type === "corporate_manager")
  //         return <CorporateUserList listHeaderRef={listHeaderRef} />;
  //     } else if (selectedLeaderboardValue === 1)
  //       return <EventList listHeaderRef={listHeaderRef} />;
  //     else if (selectedLeaderboardValue === 2)
  //       return <OfficeList listHeaderRef={listHeaderRef} />;
  //     else return null;
  //   }, [selectedLeaderboardValue, users, events, userData?.corporate]);

  const List = useMemo(() => {
    if (selectedLeaderboardValue === "user") {
      return (
        <CorporateUserList listHeaderRef={listHeaderRef} sortBy={sortBy} />
      );
    } else if (selectedLeaderboardValue === "team") {
      return <TeamList listHeaderRef={listHeaderRef} sortBy={sortBy} />;
    } else if (selectedLeaderboardValue === "company") {
      return <OfficeList listHeaderRef={listHeaderRef} sortBy={sortBy} />;
    }
  }, [selectedLeaderboardValue, sortBy]);

  return (
    <Card
      style={{
        marginTop: 20,
        position: "relative",
        pointerEvents: "all",
        flex: 1,
        overflowY: "auto",
        minWidth: 400,
      }}
    >
      <div
        ref={listHeaderRef}
        style={{
          padding: 20,
          paddingBottom: 5,
          borderStyle: "dashed",
          borderColor: colors.BLACK_20,
          borderWidth: 0,
          borderBottomWidth: 0,
        }}
      >
        <p style={{ textAlign: "start", fontWeight: "600" }}>
          {selectedLeaderboardType?.label}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 20,
            margin: "0 10px",
            opacity: 0.5,
          }}
        >
          <p style={{ fontSize: 14 }}>#</p>
          <p style={{ fontSize: 14, marginLeft: 50 }}>
            {selectedLeaderboardType?.label.split(" ")[0]}
          </p>
          <p
            style={{ fontSize: 14, marginLeft: "auto", cursor: "pointer" }}
            onClick={() =>
              setSortBy((v) => (v === "credits" ? "total_co2" : "credits"))
            }
          >
            {sortBy === "total_co2" ? "Saved Co2" : "Claimed"}
          </p>
        </div>
      </div>
      {List}
    </Card>
  );
};

export default Leaderboard;

const OfficeList = ({
  listHeaderRef,
  sortBy,
}: {
  listHeaderRef: React.RefObject<HTMLDivElement>;
  sortBy: "total_co2" | "credits";
}) => {
  const { useBranchesLeaderboard } = useStats();
  const { data = [] } = useBranchesLeaderboard();
  return (
    <div
      onScrollCapture={(e) =>
        (listHeaderRef.current!.style.borderBottomWidth =
          (e.target as any).scrollTop > 25 ? "1px" : "0px")
      }
      style={{
        overflowY: "auto",
        gap: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
        alignItems: "stretch",
      }}
    >
      {data?.map((item: any, index: number) => (
        <OfficeItem
          index={index}
          selected={false}
          item={item}
          key={index}
          sortBy={sortBy}
        />
      ))}
    </div>
  );
};
const OfficeItem = ({
  item,
  onClick,
  selected,
  index,
  sortBy,
}: {
  item: { branch_name: string; credits: number; total_co2: number };
  onClick?: () => void;
  selected: boolean;
  index: number;
  sortBy: "credits" | "total_co2";
}) => {
  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 5,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
          position: "relative",
        }}
      >
        <ImageIcon
          color="#999999"
          size={30}
          containerStyle={{
            opacity: index < 3 ? 0.4 : 0.2,
            position: "absolute",
          }}
          name={"hexagon_2"}
        />
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {index + 1}
        </p>
      </div>

      {/* <Avatar
        id={user.id}
        name={user.full_name || "Anonymous User"}
        url={user.avatar}
        size={35}
      /> */}

      <div style={{ marginLeft: 10 }}>
        <p style={{ fontWeight: "700", textAlign: "start", fontSize: 14 }}>
          {item.branch_name}
        </p>
      </div>

      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: 8,
          position: "relative",
        }}
      >
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {item[sortBy].toFixed(2)} {sortBy === "total_co2" ? "Kgs" : "Points"}
        </p>
      </div>
    </div>
  );
};

const TeamList = ({
  listHeaderRef,
  sortBy,
}: {
  listHeaderRef: React.RefObject<HTMLDivElement>;
  sortBy: "total_co2" | "credits";
}) => {
  const { useTeamsLeaderboard } = useStats();
  const { data = [] } = useTeamsLeaderboard();
  return (
    <div
      onScrollCapture={(e) =>
        (listHeaderRef.current!.style.borderBottomWidth =
          (e.target as any).scrollTop > 25 ? "1px" : "0px")
      }
      style={{
        overflowY: "auto",
        gap: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
        alignItems: "stretch",
      }}
    >
      {data?.map((item: any, index: number) => (
        <TeamItem
          index={index}
          selected={false}
          item={item}
          key={index}
          sortBy={sortBy}
        />
      ))}
    </div>
  );
};
const TeamItem = ({
  item,
  onClick,
  selected,
  index,
  sortBy,
}: {
  item: { name: string; credits: number; total_co2: number; logo?: string };
  onClick?: () => void;
  selected: boolean;
  index: number;
  sortBy: "credits" | "total_co2";
}) => {
  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 5,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
          position: "relative",
        }}
      >
        <ImageIcon
          color="#999999"
          size={30}
          containerStyle={{
            opacity: index < 3 ? 0.4 : 0.2,
            position: "absolute",
          }}
          name={"hexagon_2"}
        />
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {index + 1}
        </p>
      </div>

      {!!item.logo && (
        <img
          alt="team-logo"
          style={{ height: 35, width: 35, border:'solid 1px #eeeeee', borderRadius: 35 }}
          src={item.logo}
        />
      )}

      <div style={{ marginLeft: 10 }}>
        <p style={{ fontWeight: "700", textAlign: "start", fontSize: 14 }}>
          {item.name}
        </p>
      </div>

      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: 8,
          position: "relative",
        }}
      >
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {item[sortBy].toFixed(2)} {sortBy === "total_co2" ? "Kgs" : "Points"}
        </p>
      </div>
    </div>
  );
};

const EventList = ({
  listHeaderRef,
}: {
  listHeaderRef: React.RefObject<HTMLDivElement>;
}) => {
  const { useVenueLeaderboard } = useEvent();
  const { data: events = [] } = useVenueLeaderboard();
  const { show: showEventSummaryModal, ModalComp: EventSummaryModal } =
    useModal<EventSummaryModalProps>(({ props, hide }) => (
      <EventSummary hide={hide} props={props!} />
    ));
  const [selectedEvent, setSelectedEvent] = useSelectedEvent();
  const [center, setCenter] = useMapCenter();
  return (
    <div
      onScrollCapture={(e) =>
        (listHeaderRef.current!.style.borderBottomWidth =
          (e.target as any).scrollTop > 25 ? "1px" : "0px")
      }
      style={{
        overflowY: "auto",
        gap: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
        alignItems: "stretch",
      }}
    >
      {EventSummaryModal}
      {events.map((item, index) => (
        <EventItem
          index={index}
          onClick={() => {
            showEventSummaryModal({ event: item });
            setSelectedEvent(item);
            setCenter({
              lat: item.location.coordinates.latitude,
              lng: item.location.coordinates.longitude,
            });
          }}
          selected={item.id === selectedEvent?.id}
          event={item}
          key={index}
        />
      ))}
    </div>
  );
};

const EventItem = ({
  event,
  selected,
  onClick,
  index,
}: {
  event: Event;
  selected: boolean;
  index: number;
  onClick: () => void;
}) => {
  const { show: showSponsorModal, ModalComp: SponsorEventModal } =
    useModal<SponsorEventModalProps>(({ hide, props }) => (
      <SponsorEvent props={props!} hide={hide} />
    ));
  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 5,
        cursor: "pointer",
      }}
    >
      {SponsorEventModal}
      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
          position: "relative",
        }}
      >
        <ImageIcon
          color="#999999"
          size={30}
          containerStyle={{
            opacity: index < 3 ? 0.4 : 0.2,
            position: "absolute",
          }}
          name={"hexagon_2"}
        />
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {index + 1}
        </p>
      </div>

      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <p style={{ fontWeight: "700", textAlign: "start", fontSize: 14 }}>
          {event.name?.trim()}
        </p>
        <p style={{ fontWeight: "400", fontSize: 10, textAlign: "start" }}>
          {event.location.address}
        </p>
      </div>

      <div
        className="hover-scale hover-border"
        onClick={(e) => {
          e.stopPropagation();
          showSponsorModal({ event });
        }}
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          background: colors.LIGHT_GREEN + "90",
          padding: "8px 16px",
          borderRadius: 50,
          cursor: "pointer",
        }}
      >
        <ImageIcon containerStyle={{ cursor: "pointer" }} name={"near"} />
        <p style={{ fontWeight: "600", fontSize: 14, marginLeft: 5 }}>
          {event.bounty?.toFixed(0)}
        </p>
      </div>
    </div>
  );
};

const BountyParticipantList = ({
  listHeaderRef,
}: {
  listHeaderRef: React.RefObject<HTMLDivElement>;
}) => {
  const { useMyBounties } = useBounty();
  const { data: bounties = [] } = useMyBounties();
  const { useIdsToUsers } = useUser();
  const { data: users = [] } = useIdsToUsers(
    bounties.reduce<string[]>((t, c) => [...t, ...(c.claimants || [])], [])
  );
  const [selectedUser, setSelectedUser] = useSelectedUser();
  const TOTAL_BOUNTY = bounties.reduce(
    (t, c) => (t || 0) + (parseFloat(c.total_bounty + "") || 0),
    0
  );
  return (
    <div
      onScrollCapture={(e) =>
        (listHeaderRef.current!.style.borderBottomWidth =
          (e.target as any).scrollTop > 25 ? "1px" : "0px")
      }
      style={{
        overflowY: "auto",
        gap: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
        alignItems: "stretch",
      }}
    >
      {users.map((item, index) => (
        <BountyParticipantItem
          index={index}
          selected={item.id === selectedUser?.id}
          onClick={() => setSelectedUser(item)}
          user={item}
          amount={TOTAL_BOUNTY / users.length}
          key={index}
        />
      ))}
    </div>
  );
};

const BountyParticipantItem = ({
  user,
  onClick,
  selected,
  index,
  amount,
}: {
  user: User;
  onClick: () => void;
  selected: boolean;
  index: number;
  amount: number;
}) => {
  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 5,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
          position: "relative",
        }}
      >
        <ImageIcon
          color="#999999"
          size={30}
          containerStyle={{
            opacity: index < 3 ? 0.4 : 0.2,
            position: "absolute",
          }}
          name={"hexagon_2"}
        />
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {index + 1}
        </p>
      </div>

      <Avatar
        id={user.id}
        name={user.full_name || "Anonymous User"}
        url={user.avatar}
        size={35}
      />

      <div style={{ marginLeft: 10 }}>
        <p style={{ fontWeight: "700", textAlign: "start", fontSize: 14 }}>
          {user.full_name || "Anonymous User"}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          background: colors.LIGHT_GREEN + "90",
          padding: "8px 16px",
          borderRadius: 50,
        }}
      >
        <ImageIcon name={"near"} />
        <p style={{ fontWeight: "600", fontSize: 14, marginLeft: 5 }}>
          {amount.toFixed(0)}
        </p>
      </div>
    </div>
  );
};

const CorporateUserList = ({
  listHeaderRef,
  sortBy,
}: {
  listHeaderRef: React.RefObject<HTMLDivElement>;
  sortBy: "total_co2" | "credits";
}) => {
  const { userData } = useAuth();
  const { useCorporateUsers } = useUser();

  const { data: users = [] } = useCorporateUsers(userData?.corporate || "");
  const [selectedUser, setSelectedUser] = useSelectedUser();
  const { copyInviteLink } = useCorporate();

  const sortedUsers = useMemo(
    () => users.sort((a, b) => b[sortBy] - a[sortBy]),
    [users, sortBy]
  );

  return (
    <div
      onScrollCapture={(e) =>
        (listHeaderRef.current!.style.borderBottomWidth =
          (e.target as any).scrollTop > 25 ? "1px" : "0px")
      }
      style={{
        overflowY: "auto",
        gap: 15,
        display: "flex",
        flexDirection: "column",
        padding: 20,
        alignItems: "stretch",
      }}
    >
      {sortedUsers.map((item, index) => (
        <CorporateUserItem
          index={index}
          selected={item.id === selectedUser?.id}
          onClick={() => setSelectedUser(item)}
          user={item}
          key={index}
          sortBy={sortBy}
        />
      ))}
      <div
        onClick={copyInviteLink}
        className="hover-border"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 5,
          background: colors.BLACK_10,
          gap: 2,
          padding: 10,
          cursor: "pointer",
        }}
      >
        <MdCopyAll />
        <p style={{ fontSize: 12 }}>INVITE LINK</p>
      </div>
    </div>
  );
};

const CorporateUserItem = ({
  user,
  onClick,
  selected,
  index,
  sortBy,
}: {
  user: User;
  onClick: () => void;
  selected: boolean;
  index: number;
  sortBy: "credits" | "total_co2";
}) => {
  const { corporateTeams } = useCorporate();
  return (
    <div
      onClick={onClick}
      className={`hover-tint ${selected ? "selected" : ""}`}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 5,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 20,
          position: "relative",
        }}
      >
        <ImageIcon
          color="#999999"
          size={30}
          containerStyle={{
            opacity: index < 3 ? 0.4 : 0.2,
            position: "absolute",
          }}
          name={"hexagon_2"}
        />
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {index + 1}
        </p>
      </div>

      <Avatar
        id={user.id}
        name={user.full_name || "Anonymous User"}
        url={user.avatar}
        size={35}
      />

      <div style={{ marginLeft: 10 }}>
        <p style={{ fontWeight: "700", textAlign: "start", fontSize: 14 }}>
          {user.username || user.full_name || "Anonymous User"}
        </p>
      </div>

      <div
        style={{
          height: 30,
          width: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: 8,
          position: "relative",
        }}
      >
        <p style={{ opacity: 0.6, fontWeight: "600", fontSize: 12 }}>
          {user[sortBy].toFixed(2)} {sortBy === "total_co2" ? "Kgs" : "Points"}
        </p>
      </div>
    </div>
  );
};
