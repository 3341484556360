import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

//modular firebase imports
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyDXJXMawbaJ3dWhJZOTQf_AH2gVcrSKW8E",
//   authDomain: "thecarbongames.firebaseapp.com",
//   databaseURL: "https://thecarbongames-default-rtdb.firebaseio.com",
//   projectId: "thecarbongames",
//   storageBucket: "thecarbongames.appspot.com",
//   messagingSenderId: "722203825253",
//   appId: "1:722203825253:web:69bd27be5772c6baf4a28c",
//   measurementId: "G-XZ6KWMNNE7"
// }

// new config
const firebaseConfig = {
  apiKey: "AIzaSyDVXYpFrC2qc1FOfe0CGEdgS-hFEczXWio",
  authDomain: "thecarbongamesapp.firebaseapp.com",
  databaseURL: "https://thecarbongames-default-rtdb.firebaseio.com",
  projectId: "thecarbongamesapp",
  storageBucket: "thecarbongamesapp.appspot.com",
  messagingSenderId: "44857088188",
  appId: "1:44857088188:web:5ae1497a57b9a46abcd8b9",
  measurementId: "G-PW39ERD3L4",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();

// -- -- -- -- -- modular firebase -- -- -- -- -- //

// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const firestoreDb = getFirestore(app);
