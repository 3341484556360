import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db, firestoreDb, functions } from "../config/firebase";
import { useInfiniteQuery, useQuery } from "react-query";
import { Carpool } from "../types/Carpool";
import { useAuth } from "../providers/useAuth";
import { Log } from "../types/Log";

const carpoolsCol = collection(firestoreDb, "carpools");
const usersCol = collection(firestoreDb, "users");

const useStats = () => {
  const { userData } = useAuth();

  return {
    useCarpoolCountForDestinations: (destinationIds: string[]) =>
      useQuery({
        queryKey: ["carpool-count-for-destination", destinationIds],
        queryFn: async () => {
          const qs = destinationIds.map((destinationId) =>
            query(carpoolsCol, where("destination.id", "==", destinationId))
          );
          return (
            await Promise.all(
              qs.map(async (q) => (await getCountFromServer(q)).data().count)
            )
          ).reduce((t, c) => t + c, 0);
        },
      }),
    useBasesStats: (destinationIds: string[]) =>
      useQuery({
        queryKey: ["basic-stats", destinationIds],
        queryFn: async () => {
          const qs = destinationIds.map((destinationId) =>
            query(carpoolsCol, where("destination.id", "==", destinationId))
          );
          const carpoolDocs = (
            await Promise.all(qs.map(async (q) => (await getDocs(q)).docs))
          ).reduce((t, c) => [...t, ...c], []);
          const carpools = carpoolDocs.map(
            (doc) => new Carpool({ ...doc.data(), id: doc.id }, userData?.id)
          );

          const allPassengers = carpools
            .map((carpool) => carpool.passengersArray)
            .reduce((t, c) => [...t, ...c], []);

          return {
            totalNumberOfCarpools: carpools.length,
            numberOfUniquePassengers: allPassengers.filter(
              (p, i, arr) => arr.map((p) => p.id).indexOf(p.id) === i
            ).length,
          };
        },
      }),

    useNewUsersStats: () =>
      useQuery({
        queryKey: ["total-status", userData?.corporate, userData?.id],
        enabled: !!userData?.corporate,
        queryFn: async () => {
          const getNewUserStatsCFn = functions.httpsCallable("getNewUserStats");
          const res = await getNewUserStatsCFn({
            corporateId: userData?.corporate,
          });
          return res.data as { last30Days: number; previous30Days: number };
        },
      }),

    useGetLogs: (params?: { exclude?: string[]; include?: string[] }) =>
      useInfiniteQuery({
        queryKey: ["get-logs", userData?.id, userData?.role, params],
        queryFn: async ({ pageParam }) => {
          const getLogsCFn = functions.httpsCallable("getLogs");
          const res = await getLogsCFn({
            page: pageParam,
            exclude: params?.exclude,
            include: params?.include,
          });
          return { list: res.data as Log[], page: pageParam };
        },
        getNextPageParam: (lastPage) => {
          if (lastPage.list?.length === 0 || lastPage.list?.length % 10)
            return undefined;
          else return (lastPage.page || 0) + 1;
        },
      }),

    useBranchesLeaderboard: () =>
      useQuery({
        queryKey: ["branchesLeaderboard", userData?.corporate],
        enabled: !!userData?.corporate,
        queryFn: async () => {
          const res = await functions.httpsCallable("branchesLeaderboard")({
            corporateId: userData?.corporate,
          });
          return res.data;
        },
      }),

    useTeamsLeaderboard: () =>
      useQuery({
        queryKey: ["teamsLeaderboard", userData?.corporate],
        enabled: !!userData?.corporate,
        queryFn: async () => {
          const res = await functions.httpsCallable("teamsLeaderboard")({
            corporateId: userData?.corporate,
          });
          return res.data;
        },
      }),
  };
};

export default useStats;
