import { useCallback, useEffect, useMemo } from "react";
import { useToggle } from "react-use";
import { MdOutlineCancel } from "react-icons/md";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";

import ControlledDatePicker from "../../components/common/ControlledDatePicker";
import ControlledFileInput from "../../components/common/ControlledFileInput";
import Alert, { AlertModalProps } from "../../components/modals/Alert";
import ControlledInput from "../../components/common/ControlledInput";
import CustomCheckbox from "../../components/common/CustomCheckbox";
import CustomButton from "../../components/common/CustomButton";
import ModalHeader from "../../components/common/ModalHeader";
import useRewards from "../../hooks/useRewards";
import useModal from "../../hooks/useModal";
import { Reward } from "../../types/Reward";
import colors from "../../assets/colors";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import ControlledPicker from "../../components/common/ControlledPicker";

export type AddEventModalProps = {
  reward: Reward;
};

type FieldValues = {
  name: string;
  description: string;
  short_description?: string;
  exp_at: Date;
  price: number;
  images?: {
    // map this to images array
    image1: string | null;
    image2: string | null;
    image3: string | null;
    image4: string | null;
    image5: string | null;
  };
  metadata: { key: string; value: string }[];
  how_to_claim?: string; // map this to how_to_claim string array
  terms?: string; // map this to terms string array
  claim_url: string;
  remaining_count: number;
  company: string;
  vouchers: string;
  type: "physical" | "digital" | "blockchain";
};

const AddReward = ({
  hide,
  props,
}: {
  hide?: () => void;
  props?: AddEventModalProps;
}) => {
  const { show: showAlertModal, ModalComp: AlertModal } =
    useModal<AlertModalProps>(({ hide, props }) => (
      <Alert hide={hide} props={props!} />
    ));

  const {
    addReward: add,
    updateReward: update,
    deleteReward: deleteItem,
  } = useRewards();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>({
    defaultValues: props?.reward
      ? {
          ...props.reward,
          images: {
            image1: props.reward.images[0] || null,
            image2: props.reward.images[1] || null,
            image3: props.reward.images[2] || null,
            image4: props.reward.images[3] || null,
            image5: props.reward.images[4] || null,
          },
          how_to_claim: props.reward.how_to_claim?.[0],
          terms: props.reward.terms?.[0],
          vouchers: props.reward.vouchers?.join(),
          exp_at: new Date(props?.reward?.exp_at || Date.now()),
        }
      : { metadata: [{ key: "", value: "" }] },
  });

  const { fields, append, remove } = useFieldArray({
    name: "metadata",
    control,
  });

  const [addImages, toggleImageInput] = useToggle(
    !!props?.reward?.images &&
      Object.values(props.reward.images).reduce((t, c) => !!t || !!c, false)
  );

  const formData = watch();

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      const vouchers = data.vouchers?.split(",");
      const mappedData = {
        images: Object.values(data.images || {}).filter(Boolean),
        how_to_claim: [data.how_to_claim],
        terms: [data.terms],
        vouchers,
        remaining_count: data.type === "digital" ? vouchers.length : data.remaining_count,
        exp_at: (data.exp_at || new Date()).getTime(),
        status: props?.reward?.status || "draft",
      };

      const rewardData = JSON.parse(JSON.stringify({ ...data, ...mappedData }));

      console.log({ rewardData });

      if (!props?.reward) {
        add.mutate(rewardData, { onSuccess: hide });
      } else {
        update.mutate(
          { id: props.reward.id, data: rewardData },
          { onSuccess: hide }
        );
      }
    },
    [add, hide, props?.reward, update]
  );

  const onDelete = () => {
    showAlertModal({
      title: "Delete",
      subTitle: "Are you sure you want to delete?",
      buttons: [
        { text: "Cancel" },
        {
          text: "Yes",
          onClick: () => {
            deleteItem.mutate(props!.reward.id);
            hide?.();
          },
        },
      ],
    });
  };

  return (
    <div style={{ margin: -30, minWidth: 500 }}>
      {AlertModal}
      {hide && (
        <ModalHeader
          style={{ padding: 20 }}
          hide={hide}
          title="Add Reward"
          subtitle="Users can get rewards for their carbon credits."
        />
      )}

      {hide && <div style={{ height: 1, background: colors.BLACK_20 }} />}

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          maxHeight: "75vh",
          padding: 30,
        }}
      >
        {hide && (
          <p style={{ fontWeight: "500", fontSize: 14, marginBottom: 10 }}>
            Reward Details
          </p>
        )}
        <p
          style={{
            fontSize: 10,
            opacity: 0.7,
            marginBottom: 20,
            textAlign: "start",
          }}
        >
          Please fill in the details of the reward.
        </p>

        <ControlledInput
          label="Name"
          inputProps={{ placeholder: "Add reward name here..." }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "name",
            options: { required: true },
          }}
        />

        <ControlledInput
          label="Description"
          multiline
          inputProps={{
            placeholder: "Add reward description here...",
            style: { height: 100 },
          }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "description",
            options: { required: true },
          }}
        />

        <ControlledInput
          label="Short description"
          inputProps={{ placeholder: "60 characters maximum" }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "short_description",
            options: { required: true, maxLength: 60 },
          }}
        />

        <ControlledPicker
          label="Type"
          options={[
            { label: "Digital", value: "digital" },
            { label: "Physical", value: "physical" },
          ]}
          formProps={{
            errors,
            name: "type",
            options: { required: true },
            control,
          }}
        />

        <ControlledDatePicker
          containerStyle={{ flex: 1, marginTop: 15 }}
          label="Expiration Date"
          formProps={{
            errors,
            name: "exp_at",
            options: { required: true },
            control,
          }}
        />

        <ControlledInput
          label="Price"
          inputProps={{
            placeholder: "Price in Carbon Credits",
            type: "number",
          }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "price",
            options: { required: true, valueAsNumber: true },
          }}
        />

        <ControlledInput
          label="Affiliated company"
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "company",
            options: { required: true },
          }}
        />

        {formData.type === "digital" && (
          <Controller
            name="vouchers"
            control={control}
            rules={{
              required: {
                message: "Please select a vouchers file",
                value: true,
              },
            }}
            render={({ field: { onChange }, fieldState: { error } }) => (
              <VoucherInput onChange={onChange} error={error?.message} />
            )}
          />
        )}

        {formData.type === "physical" && (
          <ControlledInput
            label="Available Item Count"
            inputProps={{
              placeholder: "50",
              type: "number",
            }}
            containerStyle={{ marginBottom: 15 }}
            formProps={{
              register,
              errors,
              name: "remaining_count",
              options: { required: true, valueAsNumber: true },
            }}
          />
        )}

        <ControlledInput
          label="How to claim"
          inputProps={{ placeholder: "Instruction on how to claim the reward" }}
          containerStyle={{ marginBottom: 15, marginTop: 15 }}
          formProps={{
            register,
            errors,
            name: "how_to_claim",
            options: { required: true },
          }}
        />

        <ControlledInput
          label="Terms"
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "terms",
            options: { required: true },
          }}
        />

        <ControlledInput
          label="Claim URL"
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "claim_url",
            options: { required: true },
          }}
        />

        <div>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          >
            <CustomCheckbox
              containerStyle={{ marginRight: 6 }}
              value={addImages}
              label="Add Images"
              onClick={toggleImageInput}
            />
          </div>
          {addImages && (
            <div style={{ display: "flex", marginBottom: 15, gap: 15 }}>
              <ControlledFileInput
                containerStyle={{ flex: 1 }}
                formProps={{
                  errors,
                  name: "images.image1",
                  options: { required: false },
                  control,
                }}
              />
              <ControlledFileInput
                containerStyle={{ flex: 1 }}
                formProps={{
                  errors,
                  name: "images.image2",
                  options: { required: false },
                  control,
                }}
              />
              <ControlledFileInput
                containerStyle={{ flex: 1 }}
                formProps={{
                  errors,
                  name: "images.image3",
                  options: { required: false },
                  control,
                }}
              />
              <ControlledFileInput
                containerStyle={{ flex: 1 }}
                formProps={{
                  errors,
                  name: "images.image4",
                  options: { required: false },
                  control,
                }}
              />
              <ControlledFileInput
                containerStyle={{ flex: 1 }}
                formProps={{
                  errors,
                  name: "images.image5",
                  options: { required: false },
                  control,
                }}
              />
            </div>
          )}
        </div>

        {!!fields.length && (
          <p style={{ fontSize: 14, marginBottom: 6 }}>Metadata</p>
        )}

        <div
          style={{
            gap: 10,
            display: "flex",
            flexDirection: "column",
            marginBottom: 15,
          }}
        >
          {fields.map((field, index) => {
            return (
              <div
                id={field.id}
                key={field.id}
                style={{
                  display: "flex",
                  padding: 15,
                  border: "dashed #ccc 1px",
                  borderRadius: 8,
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <MdOutlineCancel
                  onClick={() => remove(index)}
                  className="hover-tint hover-border"
                  style={{
                    padding: 4,
                    color: colors.BLACK_80,
                    background: colors.BLACK_30,
                    width: 30,
                    borderRadius: 5,
                    height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 5,
                    right: 5,
                    cursor: "pointer",
                  }}
                />

                <div style={{ display: "flex", gap: 12 }}>
                  <ControlledInput
                    label="Key"
                    inputProps={{ placeholder: "Add office name here..." }}
                    containerStyle={{ flex: 1 }}
                    formProps={{
                      register,
                      errors,
                      name: `metadata.${index}.key`,
                      options: { required: true },
                    }}
                  />

                  <ControlledInput
                    key={field.id}
                    label="Value"
                    inputProps={{ placeholder: "Add office name here..." }}
                    containerStyle={{ flex: 1 }}
                    formProps={{
                      register,
                      errors,
                      name: `metadata.${index}.value`,
                      options: { required: true },
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <CustomButton
          secondary
          onClick={() => append({ key: "", value: "" })}
          label="Add metadata"
        />
      </div>

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{ display: "flex", alignItems: "center", padding: 20, gap: 20 }}
      >
        <CustomButton
          onClick={handleSubmit(onSubmit)}
          loading={add.isLoading || update.isLoading}
          style={{ flex: 1 }}
          label={props?.reward ? "Update" : "Create"}
        />

        {props?.reward && (
          <CustomButton
            onClick={onDelete}
            style={{
              background: colors.RED,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            label="Delete"
          />
        )}
      </div>
    </div>
  );
};

export default AddReward;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as any,
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: colors.PRIMARY,
};

const acceptStyle = {
  borderColor: colors.PRIMARY,
};

const rejectStyle = {
  borderColor: colors.RED,
};

function VoucherInput({
  onChange,
  error,
}: {
  onChange: (data: string) => void;
  error?: string;
}) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: { "text/csv": [".csv"], "text/html": [".html"] },
    maxFiles: 1,
  });

  const acceptedFile = useMemo(() => acceptedFiles?.[0], [acceptedFiles]);

  useEffect(() => {
    if (acceptedFile) {
      acceptedFile.text().then((csv) => {
        const json = Papa.parse(csv, { header: true });
        const vouchers = (json.data || [])
          .map((item: any) => item?.voucher)
          .filter(Boolean);
        onChange(vouchers.join());
      });
    }
  }, [acceptedFile, onChange]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <section className="container flex flex-col gap-2">
      <p style={{ fontSize: 14, marginBottom: 6 }}>Vouchers</p>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {acceptedFile ? (
          <p style={{ color: colors.PRIMARY, fontWeight: "bold" }}>
            {acceptedFile.name}
          </p>
        ) : (
          <>
            <p>Drag and drop here</p>
            <em>(Only csv files are accepted)</em>
          </>
        )}
      </div>
      {(fileRejections.length > 0 || !!error) && (
        <p style={{ color: colors.RED, fontSize: 12 }}>
          {error || "File must be of type CSV"}
        </p>
      )}
    </section>
  );
}
