const Constants = {
  header_height: 80,
};

export const leaderboardTypes = [
  { label: "User", value: "user" },
  { label: "Team", value: "team" },
  { label: "Company", value: "company" },
];

export default Constants;
