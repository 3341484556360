import Constants from "../../common/Constants";
import CustomButton from "../../components/common/CustomButton";
import DropdownOption from "../../types/Common";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/common/Tabs";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../providers/useAuth";
import Rewards from "./rewards";
import { Roles } from "../../types/Role";
import { NFTMarketPlaceView } from "./components/NFTMarketPlaceView";
import { MyNFTMarketPlaceView } from "./components/MyNFTMarketPlaceView";
import { DonateModeView } from "./components/DonateModeView";
const clientId = "40a1c30bc9886515a5a326f5a0e2abac";
const Marketplace = () => {
  const { userData } = useAuth();
  const navigate = useNavigate();

  //states
  const [marketplaceMode, setMarketPlaceMode] = useState("nft-marketplace");

  //default-hooks

  //higher-hooks

  //sub-hooks

  const marketplaceTabs = useMemo(() => {
    const tabs = [
      // { label: "Donate", value: "donate" },
      { label: "NFT Marketplace", value: "nft-marketplace" },
      { label: "My NFTs", value: "my-nfts" },
    ];

    if (userData?.role === Roles.ClientAdmin)
      tabs.push({ label: "Rewards", value: "rewards" });

    return tabs;
  }, [userData?.role]);

  return (
    <div className="flex-1">
      <div
        className="p-12 overflow-y-auto"
        style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-12 items-center gap-4">
          <p className="text-2xl font-bold">Marketplace</p>

          <div style={{ flex: 1 }} />

          <Tabs
            tabStyle={{ padding: "9px 16px", width: 140 }}
            hidable={false}
            value={marketplaceMode}
            options={marketplaceTabs}
            onSelect={(v) => setMarketPlaceMode(v as any)}
          />

          <CustomButton
            onClick={() => navigate("donation-history")}
            icon="donation"
            label="Donation History"
          />
        </div>

        {marketplaceMode === "donate" && <DonateModeView />}
        {marketplaceMode === "nft-marketplace" && <NFTMarketPlaceView />}
        {marketplaceMode === "my-nfts" && <MyNFTMarketPlaceView />}
        {marketplaceMode === "rewards" &&
          userData?.role === Roles.ClientAdmin && <Rewards />}
      </div>
    </div>
  );
};

export default Marketplace;

export const countries: DropdownOption[] = [
  {
    value: "worldwide",
    label: "Worldwide",
  },
  {
    value: "us",
    label: "United States",
  },
  {
    value: "portugal",
    label: "portugal",
  },
];

export const themes: DropdownOption[] = [
  {
    value: "all-themes",
    label: "All Themes",
  },
  {
    value: "wild-life",
    label: "Wild List",
  },
  {
    value: "river-side",
    label: "River Side",
  },
  {
    value: "healthcare",
    label: "Healthcare",
  },
];
export const corporates: DropdownOption[] = [
  {
    value: "",
    label: "All companies",
  },
  {
    value: "7Dbgl5Ja0KEjUgwzQkTD",
    label: "Company 1",
    onClick:()=>console.log()
  },

  {
    value: "7Dbgl5Ja0KEjUgwzQkTD_TEST",
    label: "Company 2",
  },
];

export const emissionAmounts: DropdownOption[] = [
  {
    value: "1",
    label: "0-1 kgs",
  },
  {
    value: "10",
    label: "1-10 kgs",
  },
  {
    value: "20",
    label: "10-20 kgs",
  },
  {
    value: "100",
    label: "20-100 kgs",
  },
];
