import React, { ReactNode, useContext, useState } from "react";
import { db, functions } from "../config/firebase";
import useFirestoreRealtimeDoc from "../hooks/firebase/useFirestoreRealtimeDoc";

// functions.useEmulator('127.0.0.1', 5555)

interface AppContextInterface {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  config?: {
    admins: string[];
    testers: string[];
  };
}
const AppContext = React.createContext({} as AppContextInterface);

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const { data: config } = useFirestoreRealtimeDoc({
    query: db.collection("config").doc("internal_dashboard_config"),
    select: (doc) => doc?.data() as { admins: string[]; testers: string[] },
  });

  return (
    <AppContext.Provider value={{ loading, setLoading, config }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useApp = () => {
  const context = useContext(AppContext);

  return {
    ...context,
  };
};
