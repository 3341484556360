const Icons = {
  carbon: require("./carbon.png"),
  stat_shoulder: require("./stat_shoulder.png"),
  hexagon: require("./hexagon.png"),
  fuel: require("./fuel.png"),
  car_top: require("./fuel.png"),
  wallet: require("./wallet.png"),
  wallet_black: require("./wallet-black.png"),
  rocket: require("./rocket.png"),
  arrow_left_circle: require("./arrow_left_circle.png"),
  calendar: require("./calendar.png"),
  logout: require("./logout.png"),
  switch_accounts: require("./switch_accounts.png"),
  personal_account: require("./personal_account.png"),
  event_account: require("./event_account.png"),
  corporate_account: require("./corporate_account.png"),
  hexagon_2: require("./hexagon_2.png"),
  add_image: require("./add_image.png"),
  near: require("./near.png"),
  sponsor: require("./sponsor.png"),
  menu: require("./menu.png"),
  verified: require("./verified.png"),
  location: require("./location.png"),
  calendar_date: require("./calendar_date.png"),
  car_side: require("./car_side.png"),
  people: require("./people.png"),
  globe: require("./globe.png"),
  trophy: require("./trophy.png"),
  shared: require("./shared.png"),
  timer: require("./timer.png"),
  review_failed: require("./review_failed.png"),
  arrow_circle_right: require("./arrow_circle_right.png"),
  donation: require("./donation.png"),
  vote: require("./vote.png"),
  leaf: require("./leaf.png"),
  earth: require("./earth.png"),
  arrow_trending: require("./arrow_trending.png"),
  exchange_arrow: require("./exchange_arrow.png"),
  money: require("./money.png"),
  purchase: require("./purchase.png"),
  distance_arrow: require("./distance_arrow.png"),
  google: require("./google.png"),
  gps: require("./gps.png"),
};

export default Icons;
