import { useEffect, useMemo, useRef, useState } from "react";

import { RenderNFTs } from "./RenderNFTs";
import { useMarketplace } from "../../../hooks";
import { corporates, countries, emissionAmounts } from "..";
import { Images, colors } from "../../../assets";
import { NFTToken } from "../../../types/NFTToken";
import Dropdown from "../../../components/common/Dropdown";
import ImageIcon from "../../../components/common/ImageIcon";
import CustomButton from "../../../components/common/CustomButton";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { Popup } from "./Popup";

export const NFTMarketPlaceView = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  //for price and Emission calculation
  const [price, setPrice] = useState<number>(0);
  const [carbonRequirement, setCarbonRequirement] = useState<number>(0);
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [selectedNFT, setSelectedNFT] = useState<NFTToken>();
  const [autoSelect, setAutoSelect] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const { useMarketplaceTokens, buyToken: purchaseToken } = useMarketplace();

  const { data, isFetching } = useMarketplaceTokens();

  const all_nft_tokens: NFTToken[] = useMemo(
    () => data?.pages?.reduce((t, c) => [...t, ...c], []) || [],
    [data]
  );

  const handleChange = (e: any, field: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    const value = Number(e.target.value);
    const price = field === "price" ? value : value * 50;
    const emission = field === "emission" ? value : value / 50;
    console.log("emission", emission, value);

    setPrice(price);
    setCarbonRequirement(emission);

    timeoutRef.current = setTimeout(() => {
      autoSelectTokens(emission);
    }, 1000);
  };

  // Function to auto-select tokens based on carbon requirement
  const autoSelectTokens = (carbonRequirement: number) => {
    let accumulatedEmission = 0;
    const selected = [];
    if (carbonRequirement > 0) {
      for (const token of all_nft_tokens) {
        if (
          accumulatedEmission + token.metadata.co2Amount <=
          carbonRequirement
        ) {
          selected.push(token.token_id);
          accumulatedEmission += token.metadata.co2Amount;
          if (accumulatedEmission >= carbonRequirement) break;
        }
      }
      if (carbonRequirement > accumulatedEmission && selected.length > 0) {
        setCarbonRequirement(parseFloat(accumulatedEmission.toFixed(2)));
        setPrice(parseFloat((accumulatedEmission * 50).toFixed(2)));
      }
    }

    setSelectedTokens(selected);
  };

  const onSelectNFT = (token: NFTToken) => {
    setSelectedNFT(token);
    setShowPopup(true);
  };

  const onTokenSelect = ({ token_id, metadata }: NFTToken) => {
    setSelectedTokens((prevSelectedTokens) => {
      const isSelected = prevSelectedTokens.includes(token_id);
      let newSelectedTokens;

      // Toggle selection
      if (isSelected) {
        newSelectedTokens = prevSelectedTokens.filter((id) => id !== token_id);
      } else {
        newSelectedTokens = [...prevSelectedTokens, token_id];
      }

      // Calculate new total carbon requirement and price
      const newCarbonRequirement = newSelectedTokens.reduce((total, id) => {
        const token = all_nft_tokens.find((t) => t.token_id === id);
        return total + (token?.metadata.co2Amount || 0);
      }, 0);

      const newPrice = parseFloat((newCarbonRequirement * 50).toFixed(2));

      // Update state
      setCarbonRequirement(parseFloat(newCarbonRequirement.toFixed(2)));
      setPrice(newPrice);

      return newSelectedTokens;
    });
  };

  const InfoBox = useMemo(() => {
    return (
      <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8">
        <div className="flex-1 flex flex-col items-start justify-between self-stretch">
          <p className="text-4xl font-semibold text-primary text-left">
            {"Contribute to sustainability by offsetting Carbon emissions"}
          </p>
          <div className="flex items-center gap-4">
            <p className="text-start text-sm">
              By purchasing environmental sustainability NFTs, you contribute a
              positive impact on the environment. To offset Carbon emissions,
              simply input the amount of Carbon emissions you want to offset and
              we’ll handle the selection. You can also individually select the
              NFTs you want to purchase.
            </p>
          </div>
          <div className="flex gap-3 items-center">
            <div className="bg-white rounded-xl flex p-3 items-center gap-4">
              <ImageIcon size={20} color={colors.BLACK_60} name="fuel" />
              <input
                className="flex-1"
                placeholder="Emissions to offset"
                onChange={(e) => handleChange(e, "emission")}
                value={carbonRequirement}
              />
              <ImageIcon
                containerStyle={{
                  background: colors.PRIMARY50 + "aa",
                  height: 30,
                  width: 30,
                  borderRadius: 100,
                  marginTop: -10,
                  marginBottom: -10,
                }}
                name="exchange_arrow"
              />
              <input
                className="flex-1"
                placeholder="Price in USD"
                onChange={(e) => handleChange(e, "price")}
                value={price}
              />
              <ImageIcon size={20} color={colors.BLACK_60} name="money" />
            </div>
            <CustomButton
              onClick={() =>
                purchaseToken.mutate({
                  token_ids: selectedTokens,
                  totalEmission: carbonRequirement,
                })
              }
              icon="purchase"
              loading={purchaseToken.isLoading}
              label="Purchase"
            />
          </div>
          <CustomCheckbox
            value={autoSelect}
            onClick={() => setAutoSelect((prev) => !prev)}
            label="Auto-select NFTs to meet emissions goal"
          />
        </div>
        <div>
          <img
            alt="illustration-2"
            className="h-[154px] w-[222px] object-contain"
            src={Images.illustration_2}
          />
          <p
            className="p-4 rounded-full mt-4"
            style={{
              color: colors.PRIMARY,
              background: colors.PRIMARY50 + "80",
            }}
          >
            {selectedTokens.length} selected = {carbonRequirement} kg
          </p>
        </div>
      </div>
    );
  }, [selectedTokens, carbonRequirement, autoSelect, purchaseToken]);

  return (
    <>
      {InfoBox}
      <div className="flex gap-2 items-center mt-12">
        <p className="mr-4 font-semibold">NFTs</p>
        <Dropdown options={countries} value="worldwide" />
        <Dropdown options={emissionAmounts} value="1" />
        <Dropdown options={corporates} value={""} />
      </div>
      <RenderNFTs
        tokens={all_nft_tokens}
        selectedTokens={selectedTokens}
        onSelectNFT={onTokenSelect}
        onClick={onSelectNFT}
        isFetching={isFetching}
      />
      {showPopup && selectedNFT && (
        <Popup onHide={setShowPopup} token={selectedNFT} />
      )}
    </>
  );
};
