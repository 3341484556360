import React, { useMemo } from "react";
import {
  FieldErrorsImpl,
  Path,
  RegisterOptions,
  Controller,
  Control,
  ControllerRenderProps,
} from "react-hook-form";
import colors from "../../assets/colors";
import Select, { GroupBase, OptionsOrGroups } from "react-select";

type Props<FieldValues, TValue> = {
  containerStyle?: React.CSSProperties;
  label: string;
  hideLabel?: boolean;
  options: OptionsOrGroups<
    {
      label: string;
      value: TValue;
    },
    GroupBase<{
      label: string;
      value: TValue;
    }>
  >;
  formProps: {
    errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>;
    name: Path<FieldValues>;
    options?: Partial<RegisterOptions>;
    control: Control<any, any>;
  };
};

const ControlledPicker = <FieldValues, TValue>({
  label,
  formProps,
  containerStyle,
  options,
  hideLabel,
}: Props<FieldValues, TValue>) => {
  const errorMessage = useMemo(() => {
    const error = formProps.errors[formProps.name];
    if (!error) return "";
    if (error?.message) return error.message + "";
    if (error.type === "pattern") return `Please enter a valid ${label}`;
    return `${label} is required`;
  }, [formProps.errors[formProps.name]]);

  const ControllerRender = ({
    field: { onChange, onBlur, value },
  }: {
    field: ControllerRenderProps<any, Path<FieldValues>>;
  }) => {
    return (
      <Select
        value={options.find(
          (option: any) =>
            JSON.stringify(option.value) === JSON.stringify(value)
        )}
        onChange={(option: any) => onChange(option.value)}
        onBlur={onBlur}
        theme={(base) => ({
          ...base,
          borderRadius: 5,
          colors: {
            ...base.colors,
            primary: colors.PRIMARY,
            primary25: colors.PRIMARY50,
            primary50: colors.PRIMARY50,
          },
        })}
        options={options}
        styles={{
          placeholder: (base) => ({ ...base, fontSize: 14, paddingLeft: 5 }),
          singleValue: (base) => ({ ...base, fontSize: 14, paddingLeft: 5 }),
          valueContainer: (base) => ({ ...base, textAlign: "start" }),
          container: (base) => ({ ...base, width: "100%" }),
          control: (base) => ({
            ...base,
            borderWidth: 0,
            backgroundColor:
              containerStyle?.backgroundColor || colors.BLUE + "12",
          }),
        }}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        alignSelf: "stretch",
        ...containerStyle,
      }}
    >
      {!hideLabel && <p style={{ fontSize: 14, marginBottom: 6 }}>{label}</p>}
      <Controller
        name={formProps.name}
        control={formProps.control}
        rules={formProps.options}
        render={ControllerRender}
      />

      {errorMessage && (
        <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default ControlledPicker;
