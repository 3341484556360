import moment from "moment";
import { Location } from "./Location";
import { Marker } from "./Marker";
import { RatingValue } from "./Rating";
import googlePolyline from "google-polyline";

export enum CarpoolStatus {
  Open = "open",
  Pickup = "pickup",
  Journey = "journey",
  Completed = "completed",
  Meetup = "meetup",
  Cancelled = "cancelled",
}

export class Carpool {
  constructor(data?: any, userId?: string) {
    this.raw = data;
    this.userId = userId;
    Object.assign(this, data);
    Object.entries(data?.passengers || {}).forEach(([key, value], index) => {
      this.passengers[key] = new CarpoolPassenger(
        value,
        {
          ...data,
          passengersArray: Object.values(data.passengers) || [],
          passengers: undefined,
        },
        userId || ""
      );
    });
  }
  corporate?: string | null;
  raw: any;
  userId?: string;
  directions?: Directions;
  passengers: { [key: string]: CarpoolPassenger } = {};
  skin?: {
    backgroundColors: string[];
    light: boolean;
  };
  car?: {
    make: string;
    model: string;
    year: string;
    seat: number;
    type: "carbon-emitting" | "electric";
  };
  owner: any;
  status?: CarpoolStatus;
  id!: string;
  team!: string;
  tripDirection!: string;
  origin!: Location;
  destination!: Location;
  credits: number = 0;
  createdAt!: number;
  completedAt!: number;
  co2Amount: number = 0;
  current_location?: Location;
  autoUpdateTime: number = 0;
  ownerRating: RatingValue = "";
  type = "carpool" as const;
  ownerVerified?: boolean;
  rules: string[] = [];
  preferences!: {
    female_passengers_only: boolean;
    verified_passengers_only: boolean;
  };

  conditions?: {
    isPickHour: boolean;
  };

  get passengersArray() {
    return Object.values(this.passengers) || [];
  }

  get isNull() {
    return !this.raw.id;
  }

  get me() {
    return this?.passengersArray?.find(
      (passenger) => passenger.id === this.userId
    );
  }

  get ownerData() {
    return this.passengersArray?.find((p) => this.owner === p.id);
  }

  get duration() {
    return this?.directions?.routes?.[0]?.legs?.reduce(
      (total: any, leg: any) => total + leg.duration.value,
      0
    ) as number;
  }

  get formattedDuration() {
    return this.duration.formatDuration();
  }

  get distance() {
    return this?.directions?.routes?.[0]?.legs?.reduce(
      (total: any, leg: any) => total + leg.distance.value,
      0
    );
  }

  get formattedDistance() {
    return `${this.distance / 1000} km`;
  }

  get departure_time() {
    return moment(Date.now()).format("HH:mm");
  }

  get estimated_arrival() {
    return moment(Date.now() + this.duration * 1000).format("HH:mm");
  }

  get markers(): Marker[] | undefined {
    return this?.passengersArray
      ?.filter((passenger) => passenger.id !== this.userId)
      ?.map((passenger) => ({
        data: passenger,
        location: passenger.location,
      }));
  }

  get occupation() {
    return (this.car?.seat || 0) + 1 - this.passengersArray.length;
  }

  get otherPassengers() {
    return this.passengersArray.filter(
      (passenger) => passenger.id !== this.userId
    );
  }

  get myPassengers() {
    if (this.userId !== this.owner) return this.passengersArray;
    return this?.passengersArray?.filter(
      (passenger) => passenger.id !== this.owner
    );
  }

  get eta() {
    return moment(this.autoUpdateTime + this.duration * 1000).format("hh:mm a");
  }

  get polyline() {
    return this.directions?.routes[0].legs
      .map((leg) => leg.steps.map((step) => step.polyline.points))
      .reduce((total, current) => [...total, ...current], [])
      .map((p) => googlePolyline.decode(p))
      .reduce((t, c) => [...t, ...c])
      .map((point) => ({ lat: point[0], lng: point[1] }));
  }
}

const polylineColors = ["#252629", "#2780b0", "#252629", "#2780b0"];
export class CarpoolPassenger {
  constructor(passenger: any, carpool: Carpool, userId: string) {
    Object.assign(this, passenger);
    this.userId = userId;
    this.carpool = carpool;
  }

  userId: string;
  name!: string;
  username?: string;
  location!: Location;
  credits?: number;
  co2Amount?: number;
  avatar!: string;
  seat!: number;
  validTrip?: boolean;
  id!: string;
  wallet_id?: string;
  status!:
    | "cancelled"
    | "requested"
    | "rejected"
    | "accepted"
    | "picked-up"
    | "selecting-seat";
  carpool: Carpool;
  type = "carpool_passenger" as const;

  get isOwner() {
    return this.carpool.owner === this.id;
  }

  get isMe() {
    return this.userId === this.id;
  }

  get index() {
    return this.carpool.passengersArray
      .sort((a, b) => a.seat - b.seat)
      .filter((passenger) => {
        // console.log('USER ID IN PASSENGER', this.userId, this.userId===passenger.id)
        if (this.userId === passenger.id) return true;
        // if(passenger.id===this.carpool.owner) return false
        if (passenger.status === "rejected") return false;
        if (passenger.status === "selecting-seat") return false;
        return true;
      })
      .map((passenger) => passenger.id)
      .indexOf(this.id);
  }

  get waypointIndex() {
    //this.index - 1 because the owner passenger will not be included in the waypoints
    const value = this.carpool.directions?.routes[0].waypoint_order.indexOf(
      this.index - 1
    );
    return value === undefined ? -1 : value;
  }

  get routeLeg() {
    return this.carpool.directions?.routes[0].legs[this.waypointIndex || 0];
  }
}

// Directions interface //////////////////////
export interface Directions {
  routes: Route[];
  status: string;
  geocoded_waypoints: GeocodedWaypoint[];
}

export interface GeocodedWaypoint {
  place_id: string;
  types: string[];
  geocoder_status: string;
}

export interface Route {
  bounds: Bounds;
  summary: string;
  legs: Leg[];
  waypoint_order: number[];
  warnings: any[];
  copyrights: string;
  overview_polyline: Polyline;
}

export interface Bounds {
  southwest: Northeast;
  northeast: Northeast;
}

export interface Northeast {
  lat: number;
  lng: number;
}

export interface Leg {
  via_waypoint: any[];
  duration: Distance;
  end_address: string;
  traffic_speed_entry: any[];
  steps: Step[];
  start_address: string;
  distance: Distance;
  end_location: Northeast;
  start_location: Northeast;
}

export interface Distance {
  value: number;
  text: string;
}

export interface Step {
  start_location: Northeast;
  end_location: Northeast;
  distance: Distance;
  html_instructions: string;
  travel_mode: TravelMode;
  polyline: Polyline;
  duration: Distance;
  maneuver?: string;
}

export interface Polyline {
  points: string;
}

export enum TravelMode {
  Driving = "DRIVING",
}
