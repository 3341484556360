import { connect, keyStores, WalletConnection } from "near-api-js";

const connectionConfig = {
  networkId: "testnet",
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
  nodeUrl: "https://rpc.testnet.near.org",
  walletUrl: "https://testnet.mynearwallet.com",
  helperUrl: "https://helper.testnet.near.org",
  explorerUrl: "https://explorer.testnet.near.org",
  headers: {},
};
export const nftContractName = process.env.REACT_APP_NFT_CONTRACTNAME;
export const marketContractName =
  process.env.REACT_APP_MARKETPLACE_CONTRACTNAME;
export const initNear = async () => {
  console.log(`Establishing connection to ${connectionConfig.networkId}`);
  const near = await connect(connectionConfig);
  console.log(`Established connection to ${connectionConfig.networkId}`);
  console.log(`Establishing wallet connection`);
  const wallet = new WalletConnection(near, "thecarbongroup");
  console.log(`wallet connection Established`);
  return { near, wallet };
};
