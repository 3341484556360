import Avatar from "./Avatar";

const ProjectItem = () => {
  return (
    <div className="flex flex-col rounded-xl overflow-hidden cursor-pointer border border-slate-100 hover:border-gray-300 transition-all">
      <img
        src="https://cdn.pixabay.com/photo/2018/08/21/23/29/forest-3622519__340.jpg"
        className="w-full h-[180px]"
      />
      <div className="bg-slate-100 flex flex-col p-4 items-start">
        <p className="text-lg font-semibold mb-2 text-start">
          10,000 Trees for the future
        </p>
        <p className="text-start text-sm mb-4">
          Support the afforestation of greater Lisbon area
        </p>
        <div className="flex items-center self-stretch">
          <div className="flex flex-col items-start">
            <p className="font-bold text-md mb-1">$1207 of $10,000</p>
            <div className="self-stretch rounded-full bg-white h-2">
              <div className="bg-primary w-1/3 h-2 rounded-full"></div>
            </div>
          </div>
          <div className="flex ml-auto">
            <Avatar
              style={{ zIndex: 20 }}
              size={40}
              url="https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_black0348.png"
              id="https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_black0348.png"
            />
            <div style={{ margin: -5 }} />
            <Avatar
              style={{ zIndex: 10 }}
              size={40}
              url="https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_black1047.png"
              id="https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_black1047.png"
            />
            <div style={{ margin: -5 }} />
            <div className="z-0 h-[40px] w-[40px] bg-black flex items-center justify-center rounded-full">
              <p className="text-white font-semibold text-xs ml-2">1.2k</p>
            </div>
          </div>
          <p className="bg-white px-4 py-2 text-sm rounded-full ml-3 font-semibold">
            Donate
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
