import { CSSProperties, useMemo } from "react";
import Icons from "../../assets/Icons";
import CssFilterConverter from "css-filter-converter";

export type ImageIconProps = {
  size?: number;
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  name: keyof typeof Icons;
  color?: string;
  onClick?: () => any;
};

const ImageIcon = ({
  size = 13,
  containerStyle,
  iconStyle,
  name,
  onClick,
  color,
}: ImageIconProps) => {
  const image = useMemo(() => Icons[name], [name]);

  const colorFilter = CssFilterConverter.hexToFilter(color || "#0000000");

  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover-scale" : ""} ${
        onClick ? "hover-opacity" : ""
      }`}
      style={{
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
        cursor: onClick ? "pointer" : "default",
        ...containerStyle,
      }}
    >
      <img
        alt="icon"
        style={{
          width: size,
          height: size,
          objectFit: "contain",
          filter: colorFilter?.color?.replaceAll(";", ""),
          ...iconStyle,
        }}
        src={image}
      />
    </div>
  );
};

export default ImageIcon;
