import React, { ReactNode } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import AuthProvider from "./AuthProvider";
import AppProvider from "./AppProvider";
import GamificationProvider from "./GamificationProvider";
import { CorporateProvider } from "./CorporateProvider";
import { EventProvider } from "../hooks/useEvent";
import useSuccessCallback from "../hooks/useSuccessCallback";
import { useNearContract } from "../hooks";

export const queryClient = new QueryClient();
export const Providers = ({ children }: { children: ReactNode }) => {
  const Children = () => {
    const { useInit } = useNearContract();
    const { useSuccessSearchParams } = useSuccessCallback();

    useSuccessSearchParams();
    useInit();
    return <>{children}</>;
  };
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthProvider>
          <GamificationProvider>
            <CorporateProvider>
              <EventProvider>
                <Children />
              </EventProvider>
            </CorporateProvider>
          </GamificationProvider>
        </AuthProvider>
      </AppProvider>
    </QueryClientProvider>
  );
};
