import colors from "../../assets/colors";
import ImageIcon from "../../components/common/ImageIcon";
import RewardItem from "../../components/common/RewardItem";
import useModal from "../../hooks/useModal";
import useRewards from "../../hooks/useRewards";
import AddReward, { AddEventModalProps } from "./addReward";

const Rewards = () => {
  const { useGetRewards } = useRewards();

  const { data: rewards = [] } = useGetRewards();

  const { show: showAddRewardModal, ModalComp: EventSummaryModal } =
    useModal<AddEventModalProps>(({ props, hide }) => (
      <AddReward hide={hide} props={props!} />
    ));

  return (
    <div className="grid grid-cols-4 gap-4 mt-6">
      {EventSummaryModal}
      {rewards.map((reward, index) => (
        <RewardItem
          onClick={() => showAddRewardModal({ reward })}
          key={index}
          item={reward}
        />
      ))}
      <div
        onClick={() => showAddRewardModal()}
        className="flex flex-col rounded-xl overflow-hidden cursor-pointer border border-slate-100 hover:border-gray-300 transition-all relative h-[300px] p-5 justify-center items-center bg-gray-50"
      >
        <ImageIcon size={50} name="add_image" color={colors.BLACK_40} />
        {/* <div className="absolute inset-0 w-full h-full -z-10">
          <img
            alt="reward-image"
            src={item.images?.[0] || fallbackImage}
            className="w-full h-full"
            style={{ objectFit: "cover" }}
          />
          <div className="absolute inset-0 bg-slate-900 opacity-70 hover:opacity-40" />
        </div>

        <Credit amount={500} />

        <div className="flex flex-col mt-auto items-start">
          <p className="text-white font-medium">$10 off at decathlon</p>
          <p className="text-white text-xs">{item.short_description}</p>

          <div className="flex justify-between self-stretch items-end">
            <div className="flex items-start flex-col">
              <p className="text-white text-xs mt-4">Company</p>
              <p className="text-white text-sm font-medium">{item.company}</p>
            </div>
            <Progress
              total={item.remaining_count + item.total_purchases}
              value={item.remaining_count}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Rewards;


/* 
 - let users redeem points for physical item
   - check if the user has enough points, do this on the backend and also generate the error message on the backend
   - generate qr code from the backend that redirects to a page on the dashboard and has a token in the query params
   - when a qr code is generated make sure to also subtract the points from the user
   - the token needs to be a jwt token signed by the backend and also will need to decoded and verified by the backend
   - if the token is invalid show an invalid token ui
   - if the token is valid show the item name and the user name and also the points it was redeemed for, and also have a button that says fulfilled
   - once the 'fulfilled' button is hit, it will always show that is has already been fulfilled
*/