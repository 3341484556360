import Images from "../../../assets/Images";
import ImageIcon from "../../../components/common/ImageIcon";
import colors from "../../../assets/colors";
import Dropdown from "../../../components/common/Dropdown";
import ProjectItem from "../../../components/common/ProjectItem";
import { useMemo } from "react";
import { countries, themes } from "..";

export const DonateModeView = () => {
  const InfoBox = useMemo(() => {
    return (
      <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8">
        <div className="flex-1 flex flex-col items-start justify-between self-stretch">
          <p className="text-4xl font-semibold text-primary text-left">
            {"Contribute to life-changing causes with your voting power"}
          </p>
          <div className="flex items-center gep-4">
            <p
              style={{ backgroundColor: colors.PRIMARY + "15" }}
              className="text-primary px-6 py-3 font-semibold rounded-full shrink-0 mr-4 text-sm"
            >
              10 tokens = 1 voting power
            </p>
            <p className="text-start text-sm">
              Your donation not only supports these causes but also gives you a
              voice. Every 10 tokens gives you 1 voting power. The more tokens
              you earn, the higher voting power you have and the more impact you
              can have on our communities..
            </p>
          </div>
          <div className="flex items-end mt-8">
            <ImageIcon
              containerStyle={{
                backgroundColor: colors.WHITE,
                borderRadius: 100,
                padding: 7,
                marginRight: 16,
              }}
              size={26}
              name="vote"
            />
            <div className="flex items-end">
              <p className="font-bold text-4xl mr-2">60</p>
              <p className="font-semibold">voting power available</p>
            </div>
          </div>
        </div>
        <img
          alt="illustration-3"
          className="h-[200px] w-[400px] object-contain"
          src={Images.illustration_3}
        />
      </div>
    );
  }, []);

  return (
    <>
      {InfoBox}
      <div className="flex gap-2 items-center mt-12">
        <p className="mr-4 font-semibold">Projects</p>
        <Dropdown options={countries} value="worldwide" />
        <Dropdown options={themes} value="all-themes" />
      </div>

      <div className="grid grid-cols-4 gap-4 mt-6">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6].map((bonus, index) => (
          <ProjectItem key={index} />
        ))}
      </div>
    </>
  );
};
