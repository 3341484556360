import { useContext } from "react";
import { useMutation } from "react-query";
import firebase from "firebase/compat/app";

import { db } from "../config/firebase";
import { useAuth } from "../providers/useAuth";
import { GamificationContext } from "../providers/GamificationProvider";

const gamificationDataColRef = db.collection("gamification_data");

const useGamification = () => {
  const { user, userData } = useAuth();

  const { template, ...context } = useContext(GamificationContext);

  const switchCards = useMutation(
    async (params: { id: string; status: "active" | "inactive" }[]) => {
      const batch = db.batch();

      const docRef = gamificationDataColRef.doc(user?.uid || "-");

      for (let i = 0; i < params.length; i++) {
        const param = params[i];
        const existingCard = context.userGamificationData?.cards.find(
          (c) => c.id === param.id
        );

        if (existingCard) {
          batch.set(
            docRef,
            { cards: firebase.firestore.FieldValue.arrayRemove(existingCard) },
            { merge: true }
          );
        }

        const updatedCard = {
          id: param.id,
          exp_at: existingCard?.exp_at || -1,
          progress: existingCard?.progress || 0,
          status: param.status,
        };

        batch.set(
          docRef,
          { cards: firebase.firestore.FieldValue.arrayUnion(updatedCard) },
          { merge: true }
        );
      }

      await batch.commit();
    }
  );

  const updateGamificationCard = useMutation({
    mutationFn: async (cardUpdateData: {
      id: string;
      multiplier: number;
      status: "active" | "inactive";
      completion_threshold?: number;
      default_user_status?: "active" | "inactive";
    }) => {
      if (!template || !userData?.corporate) return;
      const cardIndex = template.cards.findIndex(
        (c) => c.id === cardUpdateData.id
      );
      const cardData = template.cards[cardIndex];
      template.cards.splice(cardIndex, 1, {
        ...cardData,
        ...cardUpdateData,
      });
      const updatedData = {
        ...template,
        id: userData.corporate,
      };

      const docRef = db
        .collection("gamification_templates")
        .doc(userData.corporate);

      await docRef.set(JSON.parse(JSON.stringify(updatedData)));
    },
  });

  return {
    ...context,
    template,
    switchCards,
    updateGamificationCard,
  };
};

export default useGamification;
