import { useMemo, useState } from "react";

import Constants from "../../common/Constants";
import Tabs from "../../components/common/Tabs";
import colors from "../../assets/colors";
import { capitalizeFirstLetter } from "../../common/Utils";
import useGamification from "../../hooks/useGamification";
import { useAuth } from "../../providers/useAuth";
import { Roles } from "../../types/Role";
import useModal from "../../hooks/useModal";
import CustomButton from "../../components/common/CustomButton";
import { useNavigate } from "react-router-dom";
import { Carpool } from "../../types/Carpool";
import ControlledPicker from "../../components/common/ControlledPicker";
import useUser from "../../hooks/useUser";
import { useForm } from "react-hook-form";
import { User } from "../../types/User";

type FieldValues = {
  user_id: string;
  carpools: Carpool[];
};

const GamificationSimulator = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { useCorporateUsers } = useUser();
  const { template } = useGamification();
  const [category, setCategory] = useState("all");

  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FieldValues>();

  const categoryOptions = useMemo(
    () =>
      [{ label: "All", value: "all" }].concat(
        template?.card_categories.map((category: any) => ({
          label: category.name,
          value: category.id,
        })) || []
      ),
    [template?.card_categories]
  );

  const { data: users = [] } = useCorporateUsers(userData?.corporate);

  const formData = watch();

  const selectedUser = useMemo(
    () => users.find((user) => user.id === formData.user_id),
    [formData.user_id, users]
  );

  return (
    <div className="flex-1">
      <div
        className="p-12 overflow-y-auto"
        style={{ height: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-6 items-center gap-4">
          <p className="text-2xl font-bold">Gamification Simulator</p>

          <div style={{ flex: 1 }} />

          {/* <Tabs
            tabStyle={{ padding: "9px 16px", minWidth: 100 }}
            hidable={false}
            value={category}
            onSelect={(v) => setCategory(v as any)}
            options={categoryOptions}
          />

          <CustomButton
            label="Simulator"
            icon="carbon"
            iconProps={{ color: colors.WHITE }}
            onClick={()=>navigate('/gamification/simulator')}
          /> */}
        </div>

        <div className="gap-2 flex flex-col items-start">
          <Card title="User">
            <ControlledPicker
              containerStyle={{ width: 400 }}
              label="Select User"
              formProps={{ control, name: "user_id", errors }}
              options={users.map((user) => ({
                label: user.full_name || user.username || "Anonymous User",
                value: user.id,
              }))}
            />
       {selectedUser &&     <div className="self-stretch w-[1px] bg-gray-300" />}
            {selectedUser && <SelectedUserDetails user={selectedUser} />}
          </Card>
          <Card title="User">
            <ControlledPicker
              containerStyle={{ width: 400 }}
              label="Select User"
              formProps={{ control, name: "user_id", errors }}
              options={users.map((user) => ({
                label: user.full_name || user.username || "Anonymous User",
                value: user.id,
              }))}
            />
            {selectedUser && (
              <div className="self-stretch w-[1px] bg-gray-300" />
            )}
            {selectedUser && <SelectedUserDetails user={selectedUser} />}
          </Card>
        </div>

        {/* <div className="flex gap-4 flex-col"> */}
        {/* {template?.card_categories
            .filter((c) => category === "all" || category === c.id)
            .map((c: any) => (
              <Category key={c.id} categoryId={c.id} />
            ))} */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default GamificationSimulator;

const SelectedUserDetails = ({ user }: { user: User }) => {
  return (
    <div className="rounded-lg bg-gray-100 p-4 gap-1">
      <p>
        <b>Name</b> : {user.full_name || user.username}
      </p>
      <p>
        <b>Total Carpools</b> : {user.total_carpools}
      </p>
      <p>
        <b>Total Carpool Offers</b> : {user.total_offers}
      </p>
      <p>
        <b>Total Credits</b> : {user.credits}
      </p>
    </div>
  );
};

const Card = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <div className="flex flex-col items-start border rounded-[10px] p-4">
      <div className="m-[-16px] mb-4 bg-gray-100 self-stretch flex items-start p-4 rounded-tl-lg rounded-tr-[9px]">
        <p className="font-semibold text-lg">{title}</p>
      </div>
      <div className="flex gap-4">{children}</div>
    </div>
  );
};
/* 
 - enable selecting a user
 - enable selecting a bunch of gamification cards // forget about this one.. will use the main template
 - enable editing the selected gamification cards and also activating and deactivating // forget about this one.. will use the main template
 - enable creating a list of carpools > the carpools will have time, distance, vehicle type and the list of passengers
 - show the difference in carbon credits earned with and without the activated gamification cards
 - show a very simple graph of the carbon credits difference
*/
