import { useEffect, useState } from "react";

import useSafeState from "../useSafeState";
import firebase from "firebase/compat/app";

function useFirestoreRealtimeData<TData>({
  query,
  select = (s) => s as any,
  deps = [],
  resetOn = [],
}: {
  query: firebase.firestore.Query<firebase.firestore.DocumentData>;
  select: (
    snapshot?: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ) => TData;
  deps?: any[];
  resetOn?: any[];
}) {
  const [state, setState] = useSafeState<
    | {
        snapshot?: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
        status?: "error" | "success" | "loading";
        error?: Error;
      }
    | undefined
  >();

  useEffect(() => {
    setState((s) => ({ ...s, status: "loading" }));

    if (deps.map((d) => !!d).filter((d) => d === false).length > 0) return;

    const unsubscribe = query.onSnapshot(
      (snapshot) => {
        setState((s) => ({ ...s, status: "success", snapshot }));
      },
      (err) => {
        console.log(err);
        setState((s) => ({ ...s, error: err, status: "error" }));
      }
    );

    return unsubscribe;
  }, [...deps, ...resetOn]);

  useEffect(() => {
    if (resetOn.length === 0) return;
    setState(undefined);
  }, resetOn);

  return {
    data: select(state?.snapshot),
    ...state,
    clear: () => setState((s) => ({ ...s, snapshot: undefined })), // this will clear the data until the next update from firestore
  };
}

export default useFirestoreRealtimeData;
