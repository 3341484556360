import { useQuery } from "react-query";
import { createGlobalState } from "react-use";

import { User } from "../types/User";
import { db } from "../config/firebase";
import { useFirestoreRealtimeData } from "./firebase";

const usersColRef = db.collection("users");

export const useSelectedUser = createGlobalState<User | undefined>();

const useUser = () => {
  const changeUserRole = async (userId: string, newRole: string) => {
    try {
      await usersColRef.doc(userId).update({ role: newRole });
      console.log(`Role updated for user ${userId} to ${newRole}`);
    } catch (error) {
      console.error("Error updating user role: ", error);
    }
  };

  return {
    changeUserRole,
    useUserLeaderboard: () =>
      useFirestoreRealtimeData({
        query: usersColRef.orderBy("credits", "desc").limit(20),
        select: (snapshot) => {
          return snapshot?.docs.map(
            (doc) => new User({ id: doc.id, ...doc.data() }, doc.id)
          );
        },
      }),

    useUsers: (email: string) =>
      useFirestoreRealtimeData({
        query: email
          ? usersColRef.where("email", "==", email).limit(20)
          : usersColRef.limit(20),
        select: (snapshot) => {
          return snapshot?.docs.map(
            (doc) => new User({ id: doc.id, ...doc.data() }, doc.id)
          );
        },
        resetOn: [email],
      }),

    useIdsToUsers: (ids: string[]) =>
      useQuery({
        queryKey: ["ids-to-users", ids],
        queryFn: async () =>
          Promise.all(ids.map((id) => usersColRef.doc(id).get())),
        select: (snapshot) => {
          return snapshot?.map(
            (doc) => new User({ id: doc.id, ...doc.data() }, doc.id)
          );
        },
      }),

    useCorporateUsers: (corporateId?: string) =>
      useFirestoreRealtimeData({
        query: usersColRef.where("corporate", "==", corporateId || "-1"),
        select: (snapshot) => {
          return snapshot?.docs.map(
            (doc) => new User({ id: doc.id, ...doc.data() }, doc.id)
          );
        },
        deps: [corporateId],
      }),
  };
};

export default useUser;
