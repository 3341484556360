import { useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { MdAdd, MdCancel } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import colors from "../../assets/colors";
import Icons from "../../assets/Icons";
import useEvent from "../../hooks/useEvent";
import { Event } from "../../types/Event";
import ControlledInput from "../common/ControlledInput";
import ControlledPicker from "../common/ControlledPicker";
import CustomButton from "../common/CustomButton";
import CustomCheckbox from "../common/CustomCheckbox";
import ImageIcon from "../common/ImageIcon";
import ModalHeader from "../common/ModalHeader";
import useBounty from "../../hooks/useBounty";
import { useAuth } from "../../providers/useAuth";
import { Bounty } from "../../types/Bounty";

export type CreateBountyModalProps = {
  event: Event;
};

type Fields = {
  event_bounties: {
    event_id: string;
    amount: number;
  }[];
};

const CreateBounty = ({
  hide,
  props,
}: {
  hide: () => void;
  props: CreateBountyModalProps;
}) => {
  const event = props.event;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Fields>({
    defaultValues: {
      event_bounties: [{ event_id: event.id, amount: undefined }],
    },
  });
  const { fields, append, remove } = useFieldArray<Fields>({
    control,
    name: "event_bounties",
  });
  const [selectedBountyType, setSelectedBountyType] = useState<
    "shared" | "winner_takes_all"
  >("shared");
  const [max_shared, setMaxShared] = useState(3);
  const { user } = useAuth();

  const { useRelatedEvents } = useEvent();
  const { createBounty } = useBounty();
  const { data: relatedEvents = [] } = useRelatedEvents();

  const bounty = watch("event_bounties");

  console.log(bounty);

  const onSubmit: SubmitHandler<Fields> = (data) => {
    createBounty.mutate(
      new Bounty({
        events: data.event_bounties.map((bounty) => bounty.event_id),
        event_bounties: data.event_bounties.map((bounty) => ({
          ...bounty,
          location: relatedEvents.find((event) => event.id == bounty.event_id)!
            .location,
        })),
        max_shared,
        type: selectedBountyType,
        createdBy: user?.uid || "",
      }),
      {
        onSuccess: hide,
      }
    );
  };

  const BountyTypeItem = ({
    icon,
    type,
    label,
  }: {
    icon: keyof typeof Icons;
    label: string;
    type: "shared" | "winner_takes_all";
  }) => {
    const selected = selectedBountyType == type;
    return (
      <div
        onClick={() => setSelectedBountyType(type)}
        className="hover-border"
        style={{
          cursor: "pointer",
          flex: 1,
          background: selected ? colors.LIGHT_GREEN + "aa" : colors.BLACK_10,
          display: "flex",
          flexDirection: "column",
          padding: 15,
          borderRadius: 8,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ImageIcon
            size={18}
            containerStyle={{
              padding: 5,
              background: colors.WHITE,
              borderRadius: 40,
            }}
            name={icon}
          />
          <AiFillCheckCircle
            color={selected ? colors.PRIMARY : colors.TRANSPARENT}
          />
        </div>
        <p style={{ fontSize: 14, fontWeight: "500", marginTop: 20 }}>
          {label}
        </p>
      </div>
    );
  };

  return (
    <div style={{ margin: -30, minWidth: 500 }}>
      <ModalHeader
        style={{ padding: 30, paddingBottom: 10 }}
        hide={hide}
        title="Create Bounty"
        subtitle={`Decide how the top users are rewarded at your events`}
      />

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          maxHeight: "75vh",
          padding: 30,
          paddingTop: 20,
        }}
      >
        {/* <CustomCheckbox
                    label="Set up bounty for each event location"
                    containerStyle={{ marginBottom: 30 }}
                    value={true} /> */}
        {/* <p style={{ fontSize: 14, fontWeight: '500', marginBottom: 10 }} >Set bounties</p> */}
        {fields.map((m, i) => (
          <div
            key={i}
            style={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            {/* <MdCancel
                            onClick={i == 0 ? undefined : () => remove(i)}
                            className="hover-border"
                            style={{ opacity: i == 0 ? .5 : 1, backgroundColor: colors.BLUE + '12', cursor: 'pointer', color: colors.BLACK_60, padding: 8, borderRadius: 5, height: 37, marginTop: 6, width: 37 }} /> */}
            {/* <ControlledPicker
                            label='Event'
                            containerStyle={{ flex: 1 }}
                            options={relatedEvents.map(event => ({ label: event.name, value: event.id }))}
                            formProps={{ errors, name: `event_bounties.${i}.event_id`, options: { required: true }, control }} /> */}
            <ControlledInput
              label="Set Bounty"
              containerStyle={{ marginBottom: 15, flex: 1 }}
              inputProps={{ placeholder: "Amount in NEAR", type: "number" }}
              formProps={{
                register,
                errors,
                name: `event_bounties.${i}.amount`,
                options: { required: true },
              }}
            />
          </div>
        ))}

        {/* <div
                    onClick={() => append({ event_id: '', amount: 0 })}
                    className="hover-scale"
                    style={{ cursor: 'pointer', padding: 8, borderRadius: 6, background: colors.BLACK_10, display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'center', borderColor: colors.BLACK_40, borderStyle: 'solid', borderWidth: 1 }} >
                    <MdAdd style={{fontSize : 12}} />
                    <p style={{fontSize : 12}} >Add Event</p>
                </div> */}

        <p style={{ fontSize: 14, marginBottom: 10 }}>Bounty Type</p>
        <div style={{ display: "flex", gap: 10 }}>
          <BountyTypeItem
            type="winner_takes_all"
            icon="trophy"
            label="Winner takes all"
          />
          <BountyTypeItem
            type="shared"
            icon="shared"
            label="Shared among top users"
          />
        </div>

        {selectedBountyType === "shared" && (
          <p
            style={{
              fontSize: 14,
              fontWeight: "500",
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            Share rewards among
          </p>
        )}
        {selectedBountyType === "shared" && (
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <CustomCheckbox
              value={max_shared === 3}
              onClick={() => setMaxShared(3)}
              label="1-3"
            />
            <CustomCheckbox
              value={max_shared === 5}
              onClick={() => setMaxShared(5)}
              label="1-5"
            />
            <CustomCheckbox
              value={max_shared === 10}
              onClick={() => setMaxShared(10)}
              label="1-10"
            />
            <CustomCheckbox
              value={max_shared === 20}
              onClick={() => setMaxShared(20)}
              label="1-20"
            />
            <CustomCheckbox
              value={max_shared === 50}
              onClick={() => setMaxShared(50)}
              label="1-50"
            />
            <CustomCheckbox
              value={max_shared === 100}
              onClick={() => setMaxShared(100)}
              label="1-100"
            />
          </div>
        )}

        <p style={{ fontSize: 14, marginBottom: 20, marginTop: 30 }}>
          Bounty Summary
        </p>
        <div>
          {bounty
            .filter((item) => item.event_id)
            .map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p style={{ fontSize: 12 }}>
                  {
                    relatedEvents.find((event) => event.id == item.event_id)
                      ?.name
                  }
                </p>
                <p style={{ fontSize: 12 }}>{item.amount || 0} NEAR</p>
              </div>
            ))}
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', }} >
                        <p style={{ fontSize: 12 }} >Total</p>
                        <p style={{ fontSize: 12 }}>{bounty.map(b => b.amount).reduce((t, c) => (t || 0) + parseFloat((c || 0) + ''), 0)} NEAR</p>
                    </div> */}
        </div>
      </div>

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <CustomButton
        loading={createBounty.isLoading}
        onClick={handleSubmit(onSubmit)}
        style={{ margin: 20, marginTop: 20 }}
        label="Create Bounty"
        icon="sponsor"
      />
    </div>
  );
};

export default CreateBounty;
