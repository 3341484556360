import React, { ReactNode, useMemo } from "react";
import { useQuery } from "react-query";
import { auth, db } from "../config/firebase";
import useSubscriber from "../hooks/useSubscriber";
import { User } from "../types/User";
import { useApp } from "./AppProvider";

interface AuthContextInterface {
  user?: firebase.default.User | null;
  userData?: User;
  isTester?: boolean;
}

export const usersColRef = db.collection("users");
export const teamsColRef = db.collection("teams");

export const userDataRef = React.createRef<User | undefined>();

export const AuthContext = React.createContext({} as AuthContextInterface);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { config } = useApp();
  const { data: user } = useSubscriber(
    (observer: (value: firebase.default.User | null) => void) =>
      auth.onAuthStateChanged((v) => observer(v))
  );

  const isTester = useMemo(
    () => config?.testers.includes(user?.email || ""),
    [user?.email, config?.testers]
  );

  const { data: userData } = useSubscriber(
    (observer: (userData: User) => void) => {
      const unSubscribe = usersColRef.doc(user?.uid).onSnapshot(
        (snapshot) => {
          const data = new User(
            { ...snapshot?.data(), id: snapshot.id },
            user?.uid
          );
          //@ts-ignore
          userDataRef.current = data;
          return observer(data);
        },
        (err) => console.log("user data listening", err)
      );
      return unSubscribe;
    },
    [user]
  );

  return (
    <AuthContext.Provider value={{ user, userData, isTester }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
