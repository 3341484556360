import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import { useIsFetching, useIsMutating } from "react-query";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";

import {
  Home,
  Users,
  Bonuses,
  Settings,
  Overview,
  ComingSoon,
  Statistics,
  Marketplace,
  Gamification,
  EventOnboarding,
  DonationHistory,
  ActiveCarpoolDetail,
  CorporateOnboarding,
} from "./pages";
import { colors } from "./assets";
import { Roles } from "./types/Role";
import { useAuth } from "./providers/useAuth";
import { useApp } from "./providers/AppProvider";
import GamificationSimulator from "./pages/Gamification/simulator";
import RedeemReward from "./pages/Marketplace/redeemReward";

function App() {
  const { user, userData } = useAuth();
  if (user === undefined) return <h1>Loading ....</h1>;

  return (
    <div style={{ height: "100vh" }} className="App">
      <TopLoadingBar />
      <ToastContainer />
      <Routes>
        <Route path=":slug?" element={<Home />}>
          <Route index element={<Overview />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="trip-history" element={<ComingSoon />} />
          <Route path="badge-and-nfts" element={<ComingSoon />} />
          <Route
            path="settings"
            element={
              userData?.role === Roles.ClientAdmin ? (
                <Settings />
              ) : (
                <ComingSoon />
              )
            }
          />
          <Route path="users" element={<Users />} />
          <Route path="gamification" element={<Gamification />} />
          <Route
            path="gamification/simulator"
            element={<GamificationSimulator />}
          />

          <Route path="bonuses" element={<Bonuses />} />

          <Route path="marketplace">
            <Route index element={<Marketplace />} />
            <Route path="donation-history" element={<DonationHistory />} />
            <Route path="redeem" element={<RedeemReward />} />
          </Route>

          <Route path="active-carpool/:id" element={<ActiveCarpoolDetail />} />
        </Route>

        <Route path="corporate-onboarding" element={<CorporateOnboarding />} />
        <Route path="event-onboarding" element={<EventOnboarding />} />
      </Routes>
    </div>
  );
}

export default App;

const TopLoadingBar = () => {
  const ref = useRef<LoadingBarRef>(null);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const { loading } = useApp();

  useEffect(() => {
    if (loading || !!isFetching || !!isMutating) ref.current?.continuousStart();
    else ref.current?.complete();
  }, [loading, isFetching, isMutating]);

  return <LoadingBar color={colors.PRIMARY} ref={ref} />;
};
