import { useSearchParams } from "react-router-dom";
import CustomButton from "../../components/common/CustomButton";
import useRewards from "../../hooks/useRewards";

const RedeemReward = () => {
  const [searchParams] = useSearchParams();
  const { markRewardAsFulfilled, useDecodedRewardRedemptionData } =
    useRewards();

  const { data: decodedTokenData, isFetching: decodingToken } =
    useDecodedRewardRedemptionData(searchParams.get("token") || "");

  console.log({ decodedTokenData });

  if (decodingToken && !decodedTokenData)
    return <p className="text-2xl italic p-10 text-gray-400">Loading</p>;

  if (decodedTokenData.status === "invalid-token")
    return <p className="text-2xl italic p-10 text-gray-400">Invalid token</p>;

  return (
    <div className="flex flex-1 bg-gray-50 p-6 items-start justify-center">
      <div className="flex flex-col rounded-lg border bg-white p-4 gap-2 items-start min-w-96">
        <p>
          Item : <b>{decodedTokenData.itemName}</b>
        </p>
        <div className="h-[1px] self-stretch bg-gray-100" />
        <p>
          Redeemed By : <b>{decodedTokenData.username}</b>
        </p>
        <div className="h-[1px] self-stretch bg-gray-100" />
        <p>
          Carbon Points : <b>{decodedTokenData.price}</b>
        </p>
        <div className="h-[1px] self-stretch bg-gray-100" />
        <p>
          Status : <b>{decodedTokenData.fulfilled ? "Fulfilled" : "Pending"}</b>
        </p>
        {!decodedTokenData.fulfilled && (
          <div className="h-[1px] self-stretch bg-gray-100" />
        )}
        {!decodedTokenData.fulfilled && (
          <CustomButton
            loading={markRewardAsFulfilled.isLoading}
            onClick={() => markRewardAsFulfilled.mutate(decodedTokenData.id)}
            label="Mark as Fulfilled"
            style={{ borderRadius: 4, marginTop: 8, alignSelf: "stretch" }}
          />
        )}
        <p className="max-w-80 self-center text-center italic text-gray-400 text-xs leading-3">
          once an items is marked as fulfilled, it is assumed that the user has
          collected the item and the status can not be reversed
        </p>
      </div>
    </div>
  );
};

export default RedeemReward;

/* 
 - let users redeem points for physical item
   - check if the user has enough points, do this on the backend and also generate the error message on the backend
   - generate qr code from the backend that redirects to a page on the dashboard and has a token in the query params
   - when a qr code is generated make sure to also subtract the points from the user
   - the token needs to be a jwt token signed by the backend and also will need to decoded and verified by the backend
   - if the token is invalid show an invalid token ui
   - if the token is valid show the item name and the user name and also the points it was redeemed for, and also have a button that says fulfilled
   - once the 'fulfilled' button is hit, it will always show that is has already been fulfilled
*/
