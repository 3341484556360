import CustomButton from "../../../components/common/CustomButton";
import { NFTToken } from "../../../types/NFTToken";

export const Popup = ({
  token,
  listedTokens,
  onHide,
  listToken,
  unlistToken,
  burnTokens,
}: {
  token: NFTToken;
  listedTokens?: string[];
  onHide: any;
  listToken?: any;
  burnTokens?: any;
  unlistToken?: any;
}) => {
  const { metadata, token_id } = token;
  const { media, title, description } = metadata;
  const isListed = listedTokens?.includes(token_id);
  const isLoading = listToken?.isLoading || unlistToken?.isLoading;
  const isBurning = burnTokens?.isLoading;
  const onSubmit = () => {
    isListed
      ? unlistToken.mutateAsync(token.token_id)
      : listToken.mutate(token);
  };
  const onBurnToken = () => {
    burnTokens.mutate({ token_ids: [token.token_id] });
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg max-w-md">
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>
        <p className="mb-4">{description}</p>
        {media && (
          <img src={media} alt={title} className="w-full h-auto mb-4" />
        )}
        <div className="flex justify-center gap-2">
          {listedTokens && (
            <>
              <CustomButton
                onClick={onBurnToken}
                label="Burn NFT"
                loading={isBurning}
                disabled={isLoading}
              />
              <CustomButton
                onClick={onSubmit}
                label={isListed ? "Unlist" : "Put in Marketplace"}
                loading={isLoading}
                disabled={isBurning}
              />
            </>
          )}
          <CustomButton
            onClick={() => onHide(false)}
            label="Close"
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
