import { useMemo, useState } from "react";

import { RenderNFTs } from "./RenderNFTs";
import { countries, emissionAmounts } from "..";
import Dropdown from "../../../components/common/Dropdown";
import { useMarketplace } from "../../../hooks";
import { NFTToken } from "../../../types/NFTToken";

import { Popup } from "./Popup";
import CustomButton from "../../../components/common/CustomButton";

export const MyNFTMarketPlaceView = () => {
  const [selectedNFT, setSelectedNFT] = useState<NFTToken>();
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  const {
    listToken,
    unlistToken,
    burnTokens,
    useMyTokens,
    useOwnerListedTokens,
  } = useMarketplace();
  const { data: my_nft_tokens = [], isFetching } = useMyTokens();
  const { data } = useOwnerListedTokens();
  const listedTokens: any[] = useMemo(
    () => data?.pages?.reduce((t, c) => [...t, ...c], []) || [],
    [data]
  );

  const handleNFTClick = (nft: any) => {
    setSelectedNFT(nft);
    setShowPopup(true);
  };
  const onTokenSelect = ({ token_id, metadata }: NFTToken) => {
    setSelectedTokens((prevSelectedTokens) => {
      const isSelected = prevSelectedTokens.includes(token_id);
      let newSelectedTokens;

      // Toggle selection
      if (isSelected) {
        newSelectedTokens = prevSelectedTokens.filter((id) => id !== token_id);
      } else {
        newSelectedTokens = [...prevSelectedTokens, token_id];
      }

      return newSelectedTokens;
    });
  };

  return (
    <>
      <div className="flex place-items-center mt-12 justify-between">
        <div className="flex gap-2 items-center ">
          <p className="mr-4 font-semibold">NFTs</p>
          <Dropdown options={countries} value="worldwide" />
          <Dropdown options={emissionAmounts} value="1" />
        </div>
        <div>
          <CustomButton
            onClick={() => burnTokens.mutate({ token_ids: selectedTokens })}
            label="Burn NFT"
            loading={burnTokens.isLoading}
            disabled={selectedTokens.length == 0}
          />
        </div>
      </div>
      <RenderNFTs
        tokens={my_nft_tokens}
        isFetching={isFetching}
        onClick={handleNFTClick}
        selectedTokens={selectedTokens}
        onSelectNFT={onTokenSelect}
      />
      {/* Popup component for NFT details and actions */}
      {showPopup && selectedNFT && (
        <Popup
          token={selectedNFT}
          listedTokens={listedTokens}
          onHide={setShowPopup}
          listToken={listToken}
          unlistToken={unlistToken}
          burnTokens={burnTokens}
        />
      )}
    </>
  );
};
