import Constants, { leaderboardTypes } from "../common/Constants";
import CustomButton from "../components/common/CustomButton";
import MapComponent from "../components/common/MapComponent";
import UpgradeAccount from "../components/modals/UpgradeAccount";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import {
  CarsStat,
  Pools,
  Leaderboard,
  DashboardCards,
} from "../components/home";
import SubmitEvent, {
  AddEventModalProps,
} from "../components/modals/SubmitEvent";
import { Marker } from "../types/Marker";
import useModal from "../hooks/useModal";
import { colors, Images, shadow } from "../assets";
import Tabs from "../components/common/Tabs";
import { useAuth } from "../providers/useAuth";
import useCorporate from "../hooks/useCorporate";
import Card from "../components/common/Card";
import useEvent, { useSelectedEvent } from "../hooks/useEvent";
import useCarpool, { useSelectedCarpool } from "../hooks/useCarpool";
import { useMemo, useState } from "react";
import ImageIcon from "../components/common/ImageIcon";
import { MdClose } from "react-icons/md";

const Overview = () => {
  const [selectedEvent] = useSelectedEvent();
  const [selectedCarpool] = useSelectedCarpool();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);

  const { useMyEvents } = useEvent();
  const { useMyCorporate } = useCorporate();
  const { user, userData, signOut } = useAuth();

  const [carpoolsType, setCarpoolsType] = useState<number | undefined>();
  const [leaderboardType, setLeaderboardType] = useState<string | undefined>();

  const { data: events = [] } = useMyEvents();
  const { data: corporation } = useMyCorporate();

  const { useAllActiveCarpools, useCompletedCarpools } = useCarpool();
  const { data: activeCarpools = [] } = useAllActiveCarpools();
  const { data: completedCarpools = [] } = useCompletedCarpools();

  const carpools = useMemo(
    () => (carpoolsType === 0 ? activeCarpools : completedCarpools),
    [carpoolsType, activeCarpools, completedCarpools]
  );

  const { show: showSubmitEventModal, ModalComp: SubmitEventModal } =
    useModal<AddEventModalProps>(({ hide, props }) => (
      <SubmitEvent hide={hide} props={props!} />
    ));

  const activeCarpoolMarkers = useMemo(
    () =>
      activeCarpools.map(
        (carpool, index) =>
          ({
            location: carpool.current_location || carpool.origin,
            data: carpool,
          } as Marker)
      ),
    [activeCarpools]
  );

  const selectedEventMarker = useMemo(() => {
    if (selectedCarpool)
      return { location: selectedCarpool.destination, type: "event" as const };
    if (selectedEvent)
      return { location: selectedEvent?.location, data: selectedEvent };
  }, [selectedEvent, selectedCarpool]);

  const filteredCarpools = useMemo(() => {
    if (!bounds) return carpools;
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    return carpools.filter((carpool) => {
      const originLat = carpool.origin.coordinates.latitude;
      const originLng = carpool.origin.coordinates.longitude;
      const destinationLat = carpool.destination.coordinates.latitude;
      const destinationLng = carpool.destination.coordinates.longitude;

      return (
        (originLat >= sw.lat() &&
          originLat <= ne.lat() &&
          originLng >= sw.lng() &&
          originLng <= ne.lng()) ||
        (destinationLat >= sw.lat() &&
          destinationLat <= ne.lat() &&
          destinationLng >= sw.lng() &&
          destinationLng <= ne.lng())
      );
    });
  }, [bounds, carpools]);

  return (
    <div>
      {SubmitEventModal}
      <MapComponent
        onBoundsChange={setBounds}
        polylines={selectedCarpool?.polyline}
        markers={[
          ...activeCarpoolMarkers,
          ...(selectedEventMarker ? [selectedEventMarker] : []),
          ...events?.map((event) => event.marker),
          ...(selectedCarpool
            ? []
            : corporation?.branches.map((branch) => branch.marker) || []),
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          top: Constants.header_height,
          left: 0,
          bottom: 0,
          right: 0,
          pointerEvents: "none",
          padding: "30px 60px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            height: "100%",
          }}
        >
          {userData?.id && (
            <>
              <Tabs
                value={carpoolsType}
                options={carpoolsTypes}
                onSelect={setCarpoolsType}
              />

              {carpoolsType !== undefined && <CarsStat />}
              {carpoolsType !== undefined && (
                <Pools carpools={filteredCarpools} />
              )}
            </>
          )}
        </div>

        <DashboardCards userData={userData} />

        <QRCode/>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            height: "100%",
          }}
        >
          <Card
            style={{
              height: 53.5,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <p style={{ fontSize: 14 }}>Leaderboards</p>
          </Card>
          <Tabs
            value={leaderboardType}
            options={leaderboardTypes}
            onSelect={setLeaderboardType}
          />

          {leaderboardType !== undefined && (
            <Leaderboard leaderboardType={leaderboardType} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;

const carpoolsTypes = [
  { label: "Active Carpools", value: 0 },
  { label: "Completed Carpools", value: 1 },
];

const QRCode = () => {
  const [show, setShow] = useState(true)

  if(!show) return null
  return (
    <div
      style={{
        position: "absolute",
        bottom: 50,
        left: !show ? 0 : 50,
        opacity: !show ? 0 : 1,
        background: colors.WHITE,
        display: "flex",
        padding: 40,
        borderRadius: 10,
        transition: "all 200ms ease",
        pointerEvents: !show ? "none" : "all",
        ...shadow,
      }}
    >
      <img alt="QR code" src={Images.main_qr_code} style={{ height: 160 }} />

      <MdClose
        size={20}
        style={{ position: "absolute", top: 18, right: 18, cursor: "pointer" }}
        onClick={()=>setShow(false)}
      />
    </div>
  );
};

const UpgradePlanCard = ({ hide }: { hide: boolean }) => {
  const { show, ModalComp } = useModal(({ hide }) => (
    <UpgradeAccount hide={hide} />
  ));
  return (
    <div
      style={{
        position: "absolute",
        bottom: 50,
        left: hide ? 0 : 50,
        opacity: hide ? 0 : 1,
        background: colors.PRIMARY50,
        display: "flex",
        width: 430,
        padding: 20,
        borderRadius: 10,
        transition: "all 200ms ease",
        pointerEvents: hide ? "none" : "all",
      }}
    >
      {ModalComp}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <p style={{ fontWeight: "600", fontSize: 18, marginBottom: 5 }}>
          Upgrade Plan
        </p>
        <p style={{ textAlign: "start", fontSize: 12, opacity: 0.6 }}>
          Upgrade to our Corporate and Event Management plans.
        </p>

        <CustomButton
          onClick={show}
          iconProps={{ size: 18 }}
          labelStyle={{ color: colors.BLACK_100 }}
          style={{ backgroundColor: colors.WHITE, marginTop: "auto" }}
          label="Upgrade Account"
          icon="rocket"
        />
      </div>

      <img
        alt="upgrade plan"
        src={Images.illustration_1}
        style={{ height: 120 }}
      />
    </div>
  );
};

const SubmitEventCard = ({
  hide,
  onClick,
}: {
  hide: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 50,
        left: hide ? 0 : 50,
        opacity: hide ? 0 : 1,
        background: colors.ORANGE,
        display: "flex",
        width: 430,
        padding: 20,
        borderRadius: 10,
        transition: "all 200ms ease",
        pointerEvents: hide ? "none" : "all",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <p style={{ fontWeight: "600", fontSize: 18, marginBottom: 5 }}>
          Submit Event
        </p>
        <p style={{ textAlign: "start", fontSize: 12, opacity: 0.6 }}>
          Register to get carpool insights on your event.
        </p>

        <CustomButton
          onClick={onClick}
          labelStyle={{ color: colors.BLACK_100 }}
          style={{ backgroundColor: colors.WHITE, marginTop: "auto" }}
          label="Submit Event"
          icon="calendar"
        />
      </div>

      <img
        alt="submit event"
        src={Images.illustration_2}
        style={{ height: 120 }}
      />
    </div>
  );
};
