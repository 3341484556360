import { createGlobalState } from "react-use";
import { useMutation, useQuery } from "react-query";
import React, { ReactNode, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import useStripe from "./useStripe";
import { Event } from "../types/Event";
import { db } from "../config/firebase";
import { useAuth } from "../providers/useAuth";
import useFirestoreRealtimeData from "./firebase/useFirestoreRealtimeData";

const eventsColRef = db.collection("events");

export const useSelectedEvent = createGlobalState<Event | undefined>();

interface EventContextInterface {
  slugEvent?: Event;
}

const EventContext = React.createContext<EventContextInterface>(
  {} as EventContextInterface
);

export const EventProvider = ({ children }: { children: ReactNode }) => {
  const params = useParams(); //should try to use this hook instead (to get the slug)
  const location = useLocation();

  const slug = location.pathname.split("/")[1];
  const { data: slugEvent } = useQuery({
    queryKey: ["slug-event", slug],
    queryFn: async () => {
      const snapshot = await eventsColRef
        .where("slug", "==", slug || "")
        .limit(1)
        .get();
      return snapshot.docs[0]
        ? new Event({ id: snapshot.docs[0].id, ...snapshot.docs[0].data() })
        : undefined;
    },
    enabled: !!slug,
  });

  return (
    <EventContext.Provider value={{ slugEvent }}>
      {children}
    </EventContext.Provider>
  );
};

const useEvent = () => {
  const context = useContext(EventContext);
  const { slugEvent } = context;

  const { user, userData } = useAuth();
  const { createCheckoutSession } = useStripe();

  return {
    ...context,
    useVenueLeaderboard: () =>
      useFirestoreRealtimeData({
        query: eventsColRef,
        select: (snapshot) => {
          return snapshot?.docs
            ?.map((doc) => new Event({ id: doc.id, ...doc.data() }))
            .sort((a, b) => b.bounty - a.bounty);
        },
      }),

    useRelatedEvents: () =>
      useQuery({
        queryKey: ["related-events"],
        queryFn: () => eventsColRef.get(),
        select: (snapshot) => {
          return snapshot?.docs?.map(
            (doc) => new Event({ id: doc.id, ...doc.data() })
          );
        },
      }),

    useMyEvents: () =>
      useQuery({
        queryKey: ["my-events"],
        queryFn: () =>
          eventsColRef.where("createdBy", "==", user?.uid || "").get(),
        select: (snapshot) => {
          if (slugEvent) return [slugEvent];
          return snapshot?.docs?.map(
            (doc) => new Event({ id: doc.id, ...doc.data() })
          );
        },
      }),

    updateEvent: useMutation(
      async ({
        event_id,
        data,
      }: {
        event_id: string;
        data: Partial<Event>;
      }) => {
        await eventsColRef.doc(event_id).update(data);
      }
    ),

    getSingleEvent: async (event_id: string) => {
      const doc = await eventsColRef.doc(event_id).get();
      return new Event({ id: doc.id, ...doc.data() });
    },

    deleteEvent: (id: string) => eventsColRef.doc(id).delete(),

    addEvent: useMutation(async (data: Partial<Event>) => {
      const doc = await eventsColRef.add({
        ...JSON.parse(JSON.stringify(data)),
        status: "inactive",
        createdBy: user?.uid,
      });
      await createCheckoutSession.mutateAsync({ event_id: doc.id });
      // await addEventToBlockchain({
      //     "event_id": doc.id,
      //     "title": data.name,
      //     "description": data.description,
      //     "latitude": data.location?.coordinates.latitude + '',
      //     "longitude": data.location?.coordinates.longitude + '',
      // })
    }),
  };
};

export default useEvent;
