import useRewards from "../../hooks/useRewards";
import { Reward } from "../../types/Reward";
import Avatar from "./Avatar";
import CustomButton from "./CustomButton";
import ImageIcon from "./ImageIcon";
// import { Reward } from "../../types/Reward";

const fallbackImage =
  "https://as2.ftcdn.net/v2/jpg/00/95/40/95/1000_F_95409593_9mSUgggSp8z2b3Hlx5trWnoYJfcCMIvL.jpg";

const RewardItem = ({
  item,
  onClick,
}: {
  item: Reward;
  onClick: () => void;
}) => {
  const { publishReward } = useRewards();
  return (
    <div
      onClick={onClick}
      className="flex flex-col rounded-xl overflow-hidden cursor-pointer border border-slate-100 hover:border-gray-300 transition-all relative h-[300px] p-5"
    >
      <div className="absolute inset-0 w-full h-full -z-10">
        <img
          alt="reward-image"
          src={item.images?.[0] || fallbackImage}
          className="w-full h-full"
          style={{ objectFit: "cover" }}
        />
        <div className="absolute inset-0 bg-slate-900 opacity-70 hover:opacity-40" />
      </div>

      <div className="flex justify-between">
        <Credit amount={item.price} />
        {item.status !== "active" && (
          <CustomButton
            label="Publish"
            secondary
            loading={publishReward.isLoading}
            onClick={() => publishReward.mutate(item.id)}
          />
        )}
      </div>

      <div className="flex flex-col mt-auto items-start">
        <p className="text-white font-medium">{item.name}</p>
        <p className="text-white text-xs">{item.short_description}</p>

        <div className="flex justify-between self-stretch items-end">
          <div className="flex items-start flex-col">
            <p className="text-white text-xs mt-4">Company</p>
            <p className="text-white text-sm font-medium">{item.company}</p>
          </div>
          <Progress
            total={item.remaining_count + item.total_purchases}
            value={item.remaining_count}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardItem;

const Credit = ({ amount }: { amount: number }) => {
  return (
    <div className="flex gap-1 bg-[#ffffff55] rounded-full px-2 py-1 self-start">
      <ImageIcon name="carbon" color="#ffffff" />
      <p className="text-white font-bold text-sm">{amount}</p>
    </div>
  );
};

const Progress = ({ total, value }: { total: number; value: number }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="h-2 bg-[#ffffff40] w-32 rounded-full">
        <div
          style={{ width: (value / total) * 100 + "%" }}
          className="h-2 rounded-full bg-emerald-600"
        />
      </div>
      <p className="text-white text-[10px]">
        {value}/{total} left
      </p>
    </div>
  );
};
