import { SetStateAction, useState } from "react";
import { useMountedState } from "react-use";

// this is a custom hook that returns a state that doesn't cause memory leaks.
export default function useSafeState<TValue = unknown>(defaultValue?: TValue) {
  const [state, setState] = useState<TValue>(defaultValue || ({} as TValue));
  const isMounted = useMountedState();

  return [
    state,
    (newValue: SetStateAction<TValue>) =>
      isMounted()
        ? setState(newValue)
        : console.log("COMPONENT WAS UNMOUNTED. Memory leak avoided."),
  ] as const;
}
