import React, { useMemo } from "react";
import {
  FieldErrorsImpl,
  Path,
  RegisterOptions,
  Controller,
  Control,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import colors from "../../assets/colors";
import { usePlacesWidget } from "react-google-autocomplete";
import * as geofire from "geofire-common";

type Props<FieldValues> = {
  containerStyle?: React.CSSProperties;
  label: string;
  inputProps?: React.InputHTMLAttributes<any>;
  formProps: {
    errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>;
    name: Path<FieldValues>;
    options?: Partial<RegisterOptions>;
    control: Control<any, any>;
  };
};

const ControlledGoogleAutoComplete = <FieldValues,>({
  label,
  formProps,
  containerStyle,
  inputProps,
}: Props<FieldValues>) => {
  const errorMessage = useMemo(() => {
    const error = formProps.errors[formProps.name];
    if (!error) return "";
    if (error?.message) return error.message + "";
    if (error.type === "pattern") return `Please enter a valid ${label}`;
    return `${label} is required`;
  }, [formProps.errors[formProps.name]]);

  const ControllerRender = ({
    field: { onChange, onBlur, value },
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<any, Path<FieldValues>>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  }) => {
    const { ref } = usePlacesWidget<HTMLInputElement>({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      options: { types: ["establishment"] },
      onPlaceSelected: (place) => {
        onChange({
          coordinates: {
            latitude: place.geometry?.location?.lat(),
            longitude: place.geometry?.location?.lng(),
          },
          address: place.formatted_address,
          geohash: geofire.geohashForLocation([
            place.geometry?.location?.lat() || 0,
            place.geometry?.location?.lng() || 0,
          ]),
        });
      },
    });

    return (
      <input
        {...inputProps}
        ref={ref}
        defaultValue={(value as any)?.address}
        style={{
          backgroundColor: colors.BLUE + "12",
          borderRadius: 5,
          padding: 12,
          borderWidth: 0,
          minWidth: 200,
          paddingLeft: 16,
          paddingRight: 16,
          alignSelf: "stretch",
        }}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        alignSelf: "stretch",
        ...containerStyle,
      }}
    >
      <p style={{ fontSize: 14, marginBottom: 6 }}>{label}</p>
      <Controller
        name={formProps.name}
        control={formProps.control}
        rules={formProps.options}
        render={ControllerRender}
      />

      {errorMessage && (
        <p style={{ color: "red", fontSize: 12, marginTop: 5 }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default ControlledGoogleAutoComplete;
