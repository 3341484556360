import { CSSProperties, ReactNode, useCallback } from "react";
import colors from "../../assets/colors";
import Icons from "../../assets/Icons";
import ActivityIndicator from "./ActivityIndicator";
import ImageIcon, { ImageIconProps } from "./ImageIcon";

type Props = {
  label: string;
  onClick?: () => void;
  icon?: keyof typeof Icons;
  IconComp?: ReactNode;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  iconProps?: Omit<ImageIconProps, "name">;
  loading?: boolean;
  secondary?: boolean;
  disabled?: boolean;
};

const CustomButton = ({
  label,
  onClick,
  icon,
  style,
  labelStyle,
  iconProps,
  IconComp,
  loading,
  secondary,
  disabled,
}: Props) => {
  const _onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onClick?.();
    },
    [onClick]
  );
  return (
    <div
      onClick={loading || disabled ? undefined : _onClick}
      className="hover-scale hover-opacity"
      style={{
        borderRadius: 8,
        background: secondary ? colors.BLACK_20 : colors.PRIMARY,
        padding: "12px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        opacity: loading || disabled ? 0.7 : 1,
        ...style,
      }}
    >
      {!loading &&
        (IconComp || (icon && <ImageIcon name={icon} {...iconProps} />))}
      {loading && (
        <ActivityIndicator
          style={{ marginRight: 10 }}
          color={secondary ? colors.BLACK_80 : colors.WHITE}
        />
      )}
      <p
        style={{
          fontWeight: "500",
          color: secondary ? colors.BLACK_80 : colors.WHITE,
          marginLeft: IconComp || icon ? 6 : 0,
          fontSize: 14,
          ...labelStyle,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export default CustomButton;
