import { useEffect, useMemo, useRef } from "react";
import useSafeState from "../useSafeState";
import firebase from "firebase/compat/app";

function useFirestoreRealtimeDoc<TData>({
  query,
  select = (s) => s as any,
  deps = [],
  serverOnly,
}: {
  query: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  select: (
    snapshot?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ) => TData;
  deps?: any[];
  onChange?: () => void;
  serverOnly?: boolean;
}) {
  const skippedFirstRun = useRef(false);

  const [state, setState] = useSafeState<{
    snapshot?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
    status: "error" | "success" | "loading";
    error?: Error;
  }>();

  const data = useMemo(() => {
    if (!skippedFirstRun.current) skippedFirstRun.current = true;
    else return select(state.snapshot);
  }, [state.snapshot]);

  useEffect(() => {
    if (deps.map((d) => !!d).filter((d) => d == false).length > 0) return;

    return query.onSnapshot(
      (snapshot) => {
        if (serverOnly ? !snapshot.metadata.fromCache : true)
          setState((s) => ({ ...s, status: "success", snapshot }));
      },
      (err) => {
        console.log(err);
        setState((s) => ({ ...s, error: err, status: "error" }));
      }
    );
  }, [...deps]);

  return {
    data: data,
    ...state,
    clear: () => setState((s) => ({ ...s, snapshot: undefined })), // this will clear the data until the next update from firestore
  };
}

export default useFirestoreRealtimeDoc;
