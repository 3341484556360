import { useEffect, useRef } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { colors, Images } from "../assets";
import { functions } from "../config/firebase";
import useCorporate from "../hooks/useCorporate";
import { useAuth } from "../providers/useAuth";
import { Corporate } from "../types/Corporate";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import SignInSignUp from "../components/modals/SignInSignUp";
import EditCorporateDetails from "../components/modals/EditCorporateDetails";
import useEvent from "../hooks/useEvent";
import SubmitEvent from "../components/modals/SubmitEvent";

const EventOnboarding = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { user, userData } = useAuth();
  // const { onboardCorporate, useMyEvents } = useEvent()
  // const { data: corporate } = useMyCorporate()

  // useEffect(() => {
  //     const test = async ()=>{
  //         const createCheckoutSession = functions.httpsCallable('createCheckoutSession')
  //         const { data: session } = await createCheckoutSession({ event_id : 'ykvujYECMidv4PmaiNPA' })
  //         console.log(session)
  //         window.location.replace(session.url)
  //     }
  //     test()
  // }, [])

  // if (user === undefined || (!!user && !userData) || (!!userDataRef.current?.corporate && !corporate && !onboardCorporate.isLoading)) return (
  //     <div className="flex items-center justify-center bg-gray-50 h-full" >
  //         <p className="font-semibold text-center text-xl text-gray-600" >LOADING...</p>
  //     </div>
  // )

  return (
    <div
      ref={containerRef}
      className="h-full items-center flex flex-col p-8 bg-gray-50 overflow-y-auto"
    >
      <div
        style={{
          opacity: !user ? 1 : 0.5,
          borderColor: !user ? colors.PRIMARY : colors.BLACK_30,
        }}
        className="flex flex-col max-w-xl min-w-[30rem] w-full rounded-lg border-dashed border p-8 bg-white"
      >
        <img
          alt="logo"
          style={{ height: 60, alignSelf: "start" }}
          src={Images.the_carbon_games}
        />

        <p className="font-semibold text-start mt-10 text-xl border-b border-dashed pb-6 mb-6 text-gray-600">
          Your Details
        </p>
        <SignInSignUp
          onSuccess={() =>
            containerRef.current?.scroll({ top: 300, behavior: "smooth" })
          }
          hide={() => console.log("hide")}
          hideHeader
        />
      </div>

      <div
        style={{ opacity: user ? 1 : 0.5 }}
        className="flex flex-col items-center"
      >
        <div style={{ height: 30, width: 1 }} className="bg-gray-200" />
        <BsFillArrowDownCircleFill size={30} className="text-gray-400" />
        <div style={{ height: 30, width: 1 }} className="bg-gray-200" />
      </div>

      <div
        style={{
          opacity: user ? 1 : 0.5,
          borderColor: user ? colors.PRIMARY : colors.BLACK_30,
        }}
        className="flex flex-col max-w-xl min-w-[30rem] w-full rounded-lg border-dashed border p-8 bg-white"
      >
        <p className="font-semibold text-start text-xl border-b border-dashed pb-6 mb-8 text-gray-600">
          Event Details
        </p>

        <SubmitEvent />
      </div>
    </div>
  );
};

export default EventOnboarding;
